import { useMutation, useQueryClient } from 'react-query';
import { useAuthContext } from 'src/context/authContext';
import { apiClient } from 'src/shared/utils/apiClient';
import { ApiError } from 'src/shared/utils/apiError';
import { COMPETITIONS_KEYS } from 'src/pages/events/eventPage/competition/queryKeys';
import { dateToISONoTimezone } from 'src/shared/utils/dates';
import { toast } from 'react-toastify';
import { BracketConfig } from 'src/lyg/pages/competitions/bracket/mutation';

function startBracketPhase(competitionId: number, bracketConfig: BracketConfig, token: AccessToken): Promise<void> {
  return apiClient(`/v1/competitions/${competitionId}/bracket`, {
    token,
    method: 'POST',
    body: JSON.stringify({
      number_of_teams: Number(bracketConfig.numberOfTeams),
      include_runnerups: bracketConfig.includeRunnerUps ? 1 : 0,
    }),
  });
}

export function useStartBracketPhaseMutation(competitionId: number) {
  const { token } = useAuthContext();
  const queryClient = useQueryClient();

  return useMutation<void, ApiError, BracketConfig>(
    bracketConfig => startBracketPhase(competitionId, bracketConfig, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(COMPETITIONS_KEYS.bracket(competitionId));
      },
    },
  );
}

function computeNextBracketPhase(competitionId: number, token: AccessToken): Promise<void> {
  return apiClient(`/v1/competitions/${competitionId}/bracket/next`, { token, method: 'POST' });
}

export function useComputeNextBracketPhaseMutation(competitionId: number) {
  const { token } = useAuthContext();
  const queryClient = useQueryClient();

  return useMutation<void, ApiError>(() => computeNextBracketPhase(competitionId, token), {
    onSuccess: () => {
      queryClient.invalidateQueries(COMPETITIONS_KEYS.bracket(competitionId));
    },
  });
}

interface UpdateGameData {
  date: Date;
  courtNumber: number;
  gameId: number;
  competitionId: number;
}

interface UpdateGameSettings {
  matchDuration: number;
  timeoutDuration: number;
  timeoutPerTeam: number;
  gameId: number;
  competitionId: number;
}

function updateGameDate(request: UpdateGameData, token: AccessToken): Promise<void> {
  return apiClient(`/v1/competitions/${request.competitionId}/game/${request.gameId}`, {
    token,
    method: 'POST',
    body: JSON.stringify({
      game_date: dateToISONoTimezone(request.date, true),
      court_number: request.courtNumber,
    }),
  });
}

function updateGameSettings(request: UpdateGameSettings, token: AccessToken): Promise<void> {
  return apiClient(`/v1/competitions/${request.competitionId}/game/${request.gameId}/settings`, {
    token,
    method: 'POST',
    body: JSON.stringify({
      match_duration: request.matchDuration,
      timeout_duration: request.timeoutDuration,
      timeout_per_team: request.timeoutPerTeam,
    }),
  });
}

export function useEditGameDateMutation(competitionId: number) {
  const { token } = useAuthContext();
  const queryClient = useQueryClient();

  return useMutation<void, ApiError, UpdateGameData>(data => updateGameDate(data, token), {
    onSuccess: () => {
      queryClient.invalidateQueries(COMPETITIONS_KEYS.bracket(competitionId));
      queryClient.invalidateQueries(COMPETITIONS_KEYS.schedule(competitionId));
      toast.success('Game updated successfully');
    },
  });
}

export function useUpdateGameSettings(gameId: number) {
  const { token } = useAuthContext();
  const queryClient = useQueryClient();

  return useMutation<void, ApiError, UpdateGameSettings>(data => updateGameSettings(data, token), {
    onSuccess: () => {
      queryClient.invalidateQueries(COMPETITIONS_KEYS.game(gameId));
    },
  });
}
