import React from 'react';
import { Seed, SeedItem, SeedTeam, IRenderSeedProps } from 'react-brackets';
import { Edit2 } from 'react-feather';
import { useCurrentUserRole } from 'src/context/authContext';
import { LYGCompetitionGame, LYGCompetitionGameStatus } from 'src/lyg/entities/competitions/LYGCompetitionGame';
import { PrimaryInvertedButton } from 'src/shared/ui/buttons';
import { cx } from 'src/shared/utils/common';
import { THEME_COLORS } from 'src/constants';
import css from './LYGBracketMatch.module.scss';

interface TeamItemProps {
  name: string | undefined;
  score: number | undefined;
  status: LYGCompetitionGameStatus;
  isDummyGame?: boolean;
}

const TeamItem: React.FC<TeamItemProps> = ({ name, score, status, isDummyGame = false }) => {
  return (
    <SeedTeam
      style={{
        justifyContent: 'space-between',
        gap: '18px',
        height: 50,
        backgroundColor: !name
          ? 'white'
          : isDummyGame
          ? 'lightgray'
          : status === LYGCompetitionGame.Status.win
          ? THEME_COLORS.fourth
          : status === LYGCompetitionGame.Status.lose
          ? THEME_COLORS.first
          : THEME_COLORS.third,
      }}
    >
      <span className='text-start'>{name || 'TBD '}</span>
      {!isDummyGame && <span>{score || 0}</span>}
    </SeedTeam>
  );
};

interface LYGBracketMatch extends IRenderSeedProps {
  disableActions?: boolean;
  onEditScore(game: LYGCompetitionGame): void;
  onEditDate(game: LYGCompetitionGame): void;
}

export const LYGBracketMatch: React.FC<LYGBracketMatch> = ({
  seed,
  breakpoint,
  disableActions,
  onEditScore,
  onEditDate,
}) => {
  const { isLygAdmin, isAdmin } = useCurrentUserRole();
  const game = seed.game as LYGCompetitionGame;
  const isDummyGame = seed.teams[0]?.name === 'Seeded' || seed.teams[1]?.name === 'Seeded';

  return (
    <Seed mobileBreakpoint={breakpoint}>
      <SeedItem style={{ backgroundColor: 'white', color: 'black', borderRadius: '10px', overflow: 'hidden' }}>
        <button
          className={cx(css.match, 'p-0 w-100')}
          onClick={() => !disableActions && (isAdmin || isLygAdmin) && onEditScore(game)}
          disabled={!seed.teams[0].name || !seed.teams[1].name}
          style={isDummyGame ? { cursor: 'not-allowed' } : {}}
        >
          <TeamItem
            name={seed.teams[0]?.name}
            score={seed.teams[0]?.score}
            status={seed.teams[0]?.status}
            isDummyGame={isDummyGame}
          />
          <div className='ts-h-divider w-100' />
          <TeamItem
            name={seed.teams[1]?.name}
            score={seed.teams[1]?.score}
            status={seed.teams[1]?.status}
            isDummyGame={isDummyGame}
          />
        </button>
      </SeedItem>

      {/* <p className='mt-2 mb-0'>
        {seed.date}
        {(isLygAdmin || isAdmin) && (
          <PrimaryInvertedButton onClick={() => !disableActions && onEditDate(game)} loading={disableActions}>
            <Edit2 className='align-middle' />
          </PrimaryInvertedButton>
        )}
      </p> */}

      {!isDummyGame ? (
        <p className='mt-2 mb-0 d-flex w-100 ps-1 align-items-center'>
          {seed.date}

          {(isLygAdmin || isAdmin) && (
            <PrimaryInvertedButton onClick={() => !disableActions && onEditDate(game)} loading={disableActions}>
              <Edit2 className='align-middle' />
            </PrimaryInvertedButton>
          )}
        </p>
      ) : (
        // To avoid layout shift
        <div style={{ height: '34px' }} />
      )}
    </Seed>
  );
};
