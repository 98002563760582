import React, { useState } from 'react';
import { DataTable } from 'src/shared/ui/data/DataTable';
import { LYGCompetitionGame } from 'src/lyg/entities/competitions/LYGCompetitionGame';
import { DataTableModel } from 'src/shared/ui/data/DataTableModel';
import { useDateTimeFormat } from 'src/shared/hooks/useDateTimeFormat';
import { DataTableItemDialog } from 'src/shared/ui/data/DataTableItemDialog';

interface LYGPoolResultsTableProps {
  games: LYGCompetitionGame[];
  bloatTextPattern: RegExp;
}

export default function PoolSchedule({ games, bloatTextPattern }: LYGPoolResultsTableProps) {
  const { formatDate } = useDateTimeFormat();

  const removeBloatText = (str: string) => {
    return str.replace(bloatTextPattern, '');
  };

  const [model] = useState(
    new DataTableModel<LYGCompetitionGame>({
      columns: [
        {
          className: 'flex-shrink-0 flex-grow-0',
          width: 50,
          key: 'court',
          title: 'Court',
          dataCell: ({ dataItem }) => <span>Court {dataItem.court}</span>,
        },
        {
          className: 'flex-shrink-0',
          width: 70,
          key: 'time',
          title: 'Time',
          dataCell: ({ dataItem }) => <span>{formatDate(dataItem.date, 'h:mm a')}</span>,
        },
        {
          className: 'd-flex flex-shrink-0 justify-content-start',
          width: 300,
          key: 'teamA',
          title: 'Team A',
          dataCell: ({ dataItem }) => <span>{removeBloatText(dataItem.teamAName)}</span>,
        },
        {
          className: 'flex-shrink-0 d-flex align-self-center',
          width: 50,
          key: 'score',
          title: 'Score',
          dataCell: ({ dataItem }) => <span>{`${dataItem.teamAScore} - ${dataItem.teamBScore}`}</span>,
        },
        // {
        //   className: 'd-flex flex-shrink-0 ',
        //   width: 480,
        //   key: 'game',
        //   title: 'Game',
        //   dataCell: ({ dataItem }) => (
        //     <span>
        //       {removeBloatText(dataItem.teamAName)} <span className='px-2'>vs</span>{' '}
        //       {removeBloatText(dataItem.teamBName)}
        //     </span>
        //   ),
        //   // overflowBreakpoint: 420,
        // },
        {
          className: 'flex-shrink-0',
          width: 300,
          key: 'teamB',
          title: 'Team B',
          dataCell: ({ dataItem }) => <span> {removeBloatText(dataItem.teamBName)}</span>,
        },
        {
          className: 'flex-shrink-0 flex-grow-0',
          key: 'actions',
          width: 20,
          dataCell: ({ dataItem }) => {
            if (model.hasHiddenColumns) {
              return (
                <DataTableItemDialog
                  columns={model.columns}
                  dialogTitle={'Test title'}
                  dataItem={dataItem}
                  displayOnlyHiddenColumns
                />
              );
            }
            return null;
          },
          dialogDataCell: () => null,
        },
      ],
      onSort: () => null,
    }),
  );

  return (
    <div style={{ width: '100%', overflowX: 'scroll' }}>
      <div style={{ width: 'fit-content', minWidth: '100%' }}>
        <DataTable headerClassName='ts-fw-500' data={games} model={model} />
      </div>
    </div>
  );
}
