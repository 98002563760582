import { BreadcrumbMappingsWithCallableHref } from 'src/features/appLayout/ui/PageHeader/Breadcrumb';

export function getBreadcrumbMappings(isAdmin?: boolean): BreadcrumbMappingsWithCallableHref[] {
  return [
    { href: () => '/chat', title: 'pages.chat.pageTitle' },
    { href: () => '/coaches', title: 'pages.coaches.pageTitle' },
    { href: () => '/coaches/create', title: 'shared.actions.create' },
    { href: (coachId: number): string => `/coaches/${coachId}` },
    { href: (coachId: number): string => `/coaches/${coachId}/edit`, title: 'shared.actions.edit' },

    { href: (eventId: number) => `/events/${eventId}/competitions`, title: 'Competitions', isNotClickable: true },
    {
      href: (eventId: number, competitionId: number) => `/events/${eventId}/competitions/${competitionId}`,
      isNotClickable: true,
    },
    {
      href: (eventId: number, competitionId: number) => `/events/${eventId}/competitions/${competitionId}/pools`,
      title: 'Pools',
    },
    {
      href: (eventId: number, competitionId: number) => `/events/${eventId}/competitions/${competitionId}/schedule`,
      title: 'Schedule',
    },
    {
      href: (eventId: number, competitionId: number) => `/events/${eventId}/competitions/${competitionId}/bracket`,
      title: 'Bracket',
    },

    {
      href: (eventId: number) => `/calendar/competitions/${eventId}/competition`,
      title: 'Competition',
      isNotClickable: true,
    },
    {
      href: (eventId: number, competitionId: number) =>
        `/calendar/competitions/${eventId}/competition/${competitionId}`,
      isNotClickable: true,
      hidden: true,
    },
    {
      href: (eventId: number, competitionId: number) =>
        `/calendar/competitions/${eventId}/competition/${competitionId}/pools`,
      title: 'Pools',
    },
    {
      href: (eventId: number, competitionId: number) =>
        `/calendar/competitions/${eventId}/competition/${competitionId}/schedule`,
      title: 'Schedule',
    },
    {
      href: (eventId: number, competitionId: number) =>
        `/calendar/competitions/${eventId}/competition/${competitionId}/bracket`,
      title: 'Bracket',
    },

    { href: () => '/events', title: 'Events' },
    { href: (eventId: number) => `/events/${eventId}` },
    { href: (eventId: number) => `/events/${eventId}/edit`, title: 'Edit' },
    { href: () => `/events/create`, title: 'Create' },

    { href: () => '/entries', title: 'Entries' },
    { href: (entryId: number) => `/entries/open-games/${entryId}` },
    { href: (entryId: number) => `/entries/open-games/${entryId}/edit`, title: 'Edit' },
    { href: () => '/open-games/entries/create', title: 'Create' },

    { href: (entryId: number) => `/entries/school/${entryId}` },
    { href: (entryId: number) => `/entries/school/${entryId}/edit`, title: 'Edit' },
    { href: () => '/school/entries/create', title: 'Create' },

    { href: () => '/members', title: 'Members' },
    { href: (memberId: number) => `/members/${memberId}`, isNotClickable: true },
    { href: (memberId: number) => `/members/${memberId}/edit`, title: 'Edit' },
    { href: () => '/members/create', title: 'Create' },

    { href: () => '/sports', title: 'Sports' },
    { href: () => '/venues', title: 'Venues' },
    { href: () => '/schools', title: 'Schools' },
    { href: () => '/photos', title: 'Photos' },
    { href: () => '/videos', title: 'Videos' },
    { href: () => '/', title: 'Dashboard' },

    { href: () => '/competition-entries', title: 'Members' },
    { href: () => '/competition-entries/import', title: 'Import' },

    { href: () => '/calendar', title: 'pages.events.title' },
    { href: () => '/calendar/import', title: 'pages.events.import' },
    { href: () => '/calendar/create', title: 'shared.actions.create' },
    { href: (eventId: number): string => `/calendar/${eventId}/edit`, title: 'shared.actions.edit' },
    { href: (eventId: number): string => `/calendar/${eventId}` },

    { href: () => '/calendar/competitions', title: 'Competitions' },
    { href: () => '/calendar/competitions/create', title: 'shared.actions.create' },
    { href: (eventId: number): string => `/calendar/competitions/${eventId}/edit`, title: 'shared.actions.edit' },
    { href: (eventId: number): string => `/calendar/competitions/${eventId}` },

    { href: () => `/video-clips/archive`, title: 'pages.highlights.archive.pageTitle' },
    { href: (): string => `/video-clips/share`, title: 'shared.actions.share' },
    { href: () => '/video-clips', title: 'pages.highlights.pageTitle' },
    { href: (highlightId: number): string => `/video-clips/${highlightId}`, isNotClickable: true },
    { href: (highlightId: number): string => `/video-clips/${highlightId}/edit`, title: 'shared.actions.edit' },

    { href: () => '/clubs', title: 'pages.clubs.pageTitle' },
    { href: () => '/clubs/create', title: 'shared.actions.create' },
    { href: (clubId: number): string => `/clubs/${clubId}`, isNotClickable: true },
    { href: (clubId: number): string => `/clubs/${clubId}/settings`, title: 'pages.clubs.settings.pageTitle' },
    { href: (clubId: number): string => `/clubs/${clubId}/edit`, title: 'shared.actions.edit' },

    { href: () => '/membership', title: 'pages.settings.membership.pageTitle' },

    { href: () => '/players', title: 'pages.players.pageTitle', isNotClickable: true },
    { href: (playerId: number): string => `/players/${playerId}/edit`, title: 'shared.actions.edit' },
    { href: (playerId: number): string => `/players/${playerId}` },

    { href: () => '/teams', title: 'pages.teams.pageTitle' },
    { href: () => '/teams/create', title: 'shared.actions.create' },
    { href: (teamId: number): string => `/teams/${teamId}/edit`, title: 'shared.actions.edit' },
    { href: (teamId: number): string => `/teams/${teamId}` },

    { href: () => '/exercises', title: 'pages.exercises.pageTitle' },
    { href: () => '/exercises/create', title: 'shared.actions.create' },
    { href: (exerciseId: number) => `/exercises/${exerciseId}/edit`, title: 'shared.actions.edit' },
    { href: (exerciseId: number) => `/exercises/${exerciseId}/configure`, title: 'shared.actions.configure' },
    { href: (exerciseId: number) => `/exercises/${exerciseId}`, isNotClickable: true },

    { href: () => '/settings', title: 'Settings' },
    { href: () => '/' },
    { href: () => '/faq' },
    { href: () => '/feedback' },
    { href: () => '/plans' },
    // { href: () => '/settings' },
    { href: () => '/reports', title: 'pages.reports.pageTitle' },

    { href: () => '/products', title: 'pages.payments.pageTitle' },
    { href: () => '/products/create', title: 'shared.actions.create' },

    { href: () => '/targets', title: 'pages.targets.pageTitle' },
    { href: () => '/targets/create', title: 'shared.actions.create' },
    { href: (targetId: number): string => `/targets/${targetId}/edit`, title: 'shared.actions.edit' },

    { href: () => '/stats', hidden: true },
    { href: () => '/stats/training', title: 'pages.trainingStats.pageTitle' },
    { href: () => '/stats/game', title: 'pages.gameStats.pageTitle' },
    { href: (gameId: number) => `/stats/game/${gameId}` },
    { href: (gameId: number) => `/stats/game/${gameId}/edit`, title: 'shared.actions.edit' },

    { href: () => '/subscriptions', title: 'pages.subscriptions.title' },
    { href: () => '/subscriptions/create', title: 'shared.actions.create' },
    { href: () => '/subscriptions/setup', title: 'pages.subscriptions.setup' },

    { href: () => '/videos', title: '', hidden: true },
    { href: () => '/videos/library', title: 'pages.videos.pageTitle' },
    { href: () => '/videos/library/create', title: 'shared.actions.upload' },
    { href: (videoId: number): string => `/videos/library/${videoId}/edit`, title: 'shared.actions.edit' },
    { href: (videoId: number): string => `/videos/library/${videoId}` },

    { href: () => '/billing', title: 'Billing' },
    { href: () => '/club-website', title: 'Club Website' },
  ];
}
