import { apiClient } from 'src/shared/utils/apiClient';

interface GetClubResponse {
  code: number;
  error: boolean;
  message: string;
  response: {
    basic_number_of_players: number | null;
    created_at: string;
    created_by: number;
    deleted_at: null;
    head_coach: {
      admin_first_login: DateTimeString;
      admin_last_login: DateTimeString;
      admin_login_counter: number;
      confirm_email_token: string;
      created_at: DateTimeString;
      debugging: 1 | 0;
      deleted_at: DateTimeString | null;
      direct_registration_flag: 1 | 0;
      email: string;
      email_confirmation: 1 | 0;
      email_newsletter: 1 | 0;
      email_notifications: 1 | 0;
      email_personal: 1 | 0;
      email_team: 1 | 0;
      first_login_at: DateTimeString | null;
      last_login_at: DateTimeString | null;
      login_counter: number;
      onboarding: 1 | 0;
      onboarding_coach: 1 | 0;
      onboarding_payment: 1 | 0;
      org_id: number;
      pass_reset_token: string | null;
      profile_avatar: string | null;
      profile_first_name: string;
      profile_id: number;
      profile_last_name: string;
      profile_role: string;
      provider: string | null;
      provider_user_id: string | null;
      push_newsletter: 1 | 0;
      push_notifications: 1 | 0;
      push_personal: 1 | 0;
      push_team: 1 | 0;
      reset_token_updated: string | null;
      show_skeleton_flag: 0 | 1;
      strava_integration: 0 | 1;
      team_id: number | null;
      time_zone: string | null;
      updated_at: DateTimeString;
      user_id: number;
      user_pending_coach_approval: 0 | 1;
    }[];
    flag_allow_sharing_clips_to_individual_players: 0 | 1;
    flag_limit_of_free_videos: number;
    org_avatar: string | null;
    org_description: string | null;
    org_id: number;
    org_name: string;
    org_season_end_day: string | null;
    org_season_month_month: string | null;
    org_season_start_day: string | null;
    org_season_start_month: string | null;
    payment_id: string | null;
    third_party_api_key: string | null;
    third_party_enabled: 0 | 1;
    updated_at: string | null;
    org_referral_code: string | null;
    org_site_code: string;
    org_site_twitter: string | null;
    org_site_facebook: string | null;
    org_site_instagram: string | null;
    org_site_youtube: string | null;
    org_site_image1: string | null;
    org_site_image2: string | null;
    org_site_phone1: string | null;
    org_site_phone2: string | null;
    org_site_email1: string;
    org_site_email2: string | null;
    org_site_url: string | null;
    org_site_address: string;
    org_site_disclaimer: string;
    org_site_show_teams: 0 | 1;
    org_site_show_team_squad: 0 | 1;
    org_site_show_competitions: 0 | 1;
    org_site_show_download_app: 0 | 1;
    org_site_show_calendar: 0 | 1;
    org_site_competitions_team_entry: 0 | 1;
    org_site_competitions_title: string | null;
    org_site_team_title: string | null;
    sport_id: number;
    org_site_show_contactus: 0 | 1;
    total_number_players: number;
  }[];
}

export async function getClub(clubId: number, token: AccessToken): Promise<GetClubResponse> {
  return apiClient(`/organisations/${clubId}`, { token });
}
