import { useQuery, UseQueryResult } from 'react-query';
import { useAuthContext } from 'src/context/authContext';
import { getClub } from 'src/entities/clubs/apis/getClub';
import { ClubSimple } from 'src/entities/clubs/domain';
import { CLUB_QUERY_KEYS } from 'src/entities/clubs/queries/queryKeys';

export function useClubQuery(clubId?: number): UseQueryResult<ClubSimple, Error> {
  const {
    token,
    currentUser: { clubId: defaultClubId },
  } = useAuthContext();

  return useQuery<ClubSimple, Error>(
    CLUB_QUERY_KEYS.byId(clubId || defaultClubId),
    async () => {
      const { response } = await getClub(clubId || defaultClubId, token);

      return {
        website: {
          publish: true,
          disclaimer: response[0].org_site_disclaimer,
          email: response[0].org_site_email1,
          phone: response[0].org_site_phone1 || '',
          headerImage: response[0].org_site_image1,
          facebook: response[0].org_site_facebook || '',
          twitter: response[0].org_site_twitter || '',
          instagram: response[0].org_site_instagram || '',
          youtube: response[0].org_site_youtube || '',
          address: response[0].org_site_address,
          url: response[0].org_site_url || null,
          code: response[0].org_site_code,
          showTeams: Boolean(response[0].org_site_show_teams),
          showPlayerDetails: Boolean(response[0].org_site_show_team_squad),
          teamsTitle: response[0].org_site_team_title || null,
          competitionsTitle: response[0].org_site_competitions_title || null,
          showCompetitions: Boolean(response[0].org_site_show_competitions),
          showCompetitionsCalendar: Boolean(response[0].org_site_show_calendar),
          allowTeamEntries: Boolean(response[0].org_site_competitions_team_entry),
          toggleShowCalendarPage: Boolean(response[0].org_site_show_calendar),
          toggleShowContactPage: Boolean(response[0].org_site_show_contactus),
        },
        avatarUrl: response[0].org_avatar || undefined,
        created: new Date(response[0].created_at),
        description: response[0].org_description,
        id: response[0].org_id,
        name: response[0].org_name,
        numberOfPlayers: response[0].basic_number_of_players || 0,
        isClipsShareAllowedToIndividualPlayers:
          response[0].flag_allow_sharing_clips_to_individual_players === 0 ? false : true,
        freeVideosLimit: response[0].flag_limit_of_free_videos,
        sportId: response[0].sport_id,
        headCoaches: response[0].head_coach.map(({ profile_first_name, profile_last_name, email, user_id }) => ({
          firstName: profile_first_name,
          lastName: profile_last_name,
          email,
          id: user_id,
        })),
        totalNumberOfPlayers: Number(response[0].total_number_players),
      };
    },
    {
      enabled: clubId !== undefined ? !isNaN(clubId) : true,
      staleTime: 1000 * 60 * 30,
    },
  );
}
