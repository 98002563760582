import React from 'react';
import { UseQueryResult } from 'react-query';
import { PageHeader } from 'src/features/appLayout/ui/PageHeader';
import { RippleAnimation } from 'src/shared/ui/assets/RippleAnimation';
import { UnauthorisedPage } from 'src/shared/ui/layout/UnauthorisedPage';
import { QueryLoader, QueryResultWithData } from 'src/shared/ui/utils/QueryLoader';
import { cx } from 'src/shared/utils/common';
import { IntroHelper } from 'src/features/appLayout/ui/IntroHelper';
import { IntroStep } from 'src/features/appLayout/ui/IntroHelper/introConfig';
import css from './AuthenticatedPage.module.scss';

interface AuthenticatedPageCommonProps {
  title?: string;
  routeState?: unknown;
  renderIf?: boolean;
  withoutHeader?: boolean;
  className?: string;
}

interface WithIntroSteps extends AuthenticatedPageCommonProps {
  introSteps: IntroStep[];
  pageId: string;
}
interface WithoutIntroSteps extends AuthenticatedPageCommonProps {
  introSteps?: undefined;
  pageId?: undefined;
}

type AuthenticatedPagePropsWithQuery<T> = {
  children: (queryResult: QueryResultWithData<T, Error>) => React.ReactElement | React.ReactElement[] | null;
  query: UseQueryResult<T, Error>;
  loader?: React.ReactElement;
} & AuthenticatedPageCommonProps &
  (WithIntroSteps | WithoutIntroSteps);

type AuthenticatedPagePropsWithoutQuery = {
  children: () => React.ReactElement | React.ReactElement[] | null;
  query?: undefined;
} & AuthenticatedPageCommonProps &
  (WithIntroSteps | WithoutIntroSteps);

type AuthenticatedPageProps<T> = AuthenticatedPagePropsWithoutQuery | AuthenticatedPagePropsWithQuery<T>;

export function AuthenticatedPage<T>(props: AuthenticatedPageProps<T>) {
  const { title, query, routeState, withoutHeader, introSteps, pageId, renderIf = true, className } = props;

  return renderIf ? (
    <div className={cx('ts-fade-in-03', className)}>
      <IntroHelper steps={introSteps} pageId={pageId}>
        {!withoutHeader && <PageHeader title={title} routeState={routeState} />}
        {query ? (
          <QueryLoader
            onLoading={
              props.loader || <RippleAnimation className={cx('m-auto ts-color-primary d-block', css.loader)} />
            }
            query={query}
          >
            {queryResult => props.children(queryResult)}
          </QueryLoader>
        ) : (
          props.children()
        )}
      </IntroHelper>
    </div>
  ) : (
    <UnauthorisedPage />
  );
}
