import { makeObservable, action, observable, computed } from 'mobx';
import { I18nModel } from 'src/context/i18n/I18nModel';
import { TEXT_FIELD_TYPE, TextFieldModel } from 'src/shared/ui/inputs/text/TextFieldModel';
import { BooleanFieldModel } from 'src/shared/ui/inputs/boolean/BooleanFieldModel';
import { stringIsEmailAddress } from 'src/shared/utils/strings';

export interface PlayerFormFields {
  firstName: TextFieldModel;
  lastName: TextFieldModel;
  email: TextFieldModel;
}

export interface PlayerData {
  firstName: string;
  lastName: string;
  email: string;
}

export class CreateTeamPlayerModel {
  players: PlayerFormFields[] = [];
  readonly notifyPlayersField: BooleanFieldModel;
  i18n: I18nModel;

  constructor({ i18n }: { i18n: I18nModel }) {
    this.i18n = i18n;
    this.notifyPlayersField = new BooleanFieldModel({
      i18n,
      id: 'notifyPlayers',
      initialValue: false,
      label: 'Notify players - this will send them a welcome email',
      title: 'Notify Players',
    });
    this.addPlayer();

    makeObservable(this, {
      players: observable.ref,
      i18n: observable,
      hasError: computed,
      hasChanged: computed,
      data: computed,
      addPlayer: action.bound,
      removePlayer: action.bound,
      setNotifyPlayers: action.bound,
    });
  }

  get hasError(): boolean {
    const result = this.players.some(player => {
      const firstNameError = player.firstName.hasError;
      const lastNameError = player.lastName.hasError;
      const emailError = player.email.hasError;

      return firstNameError || lastNameError || emailError;
    });

    return result;
  }

  get hasChanged(): boolean {
    return this.players.some(
      player => player.firstName.hasChanged || player.lastName.hasChanged || player.email.hasChanged,
    );
  }

  get data(): PlayerData[] {
    return this.players.map(player => ({
      firstName: player.firstName.value,
      lastName: player.lastName.value,
      email: player.email.value,
    }));
  }

  addPlayer(): void {
    this.players = [
      ...this.players,
      {
        firstName: new TextFieldModel({
          i18n: this.i18n,
          id: `firstName_${this.players.length}`,
          title: 'First Name',
          required: true,
          initialValue: '',
        }),
        lastName: new TextFieldModel({
          i18n: this.i18n,
          id: `lastName_${this.players.length}`,
          title: 'Last Name',
          required: true,
          initialValue: '',
        }),
        email: new TextFieldModel({
          i18n: this.i18n,
          id: `email_${this.players.length}`,
          title: 'Email',
          required: true,
          initialValue: '',
          type: TEXT_FIELD_TYPE.email,
          validators: [
            {
              message: this.i18n.t('shared.errors.invalidEmail'),
              validate: value => stringIsEmailAddress(value),
            },
          ],
        }),
      },
    ];
  }

  removePlayer(index: number): void {
    this.players = this.players.filter((_, i) => i !== index);
  }

  setNotifyPlayers(value: boolean): void {
    this.notifyPlayersField.setValue(value);
  }
}
