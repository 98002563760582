import { action, makeObservable, observable, reaction } from 'mobx';
import { InputFieldModel, InputFieldModelProps } from 'src/shared/ui/inputs/InputFieldModel';
import { debounce, getNonRefValue } from 'src/shared/utils/common';

export const DROPDOWN_ALL_OPTION_ID = 'all';

export interface DropdownOption {
  id: string;
  label: string;
}

interface DropdownFieldModelProps extends InputFieldModelProps<DropdownOption> {
  localSearchDisabled?: boolean;
  options?: DropdownOption[];
  withSearchbox?: boolean;
  withEmptyOption?: boolean;
  emptyLabel?: string;
  isFirstOptionDefault?: boolean;
}

export class DropdownFieldModel extends InputFieldModel<DropdownOption, DropdownFieldModelProps> {
  debouncedSearchValue = '';
  isLoading = false;
  opened = false;
  isOptionNotListed = false;
  options: DropdownOption[] = [];
  searchValue = '';
  isFirstOptionDefault = false;

  constructor(props: DropdownFieldModelProps) {
    super(props);
    this.isFirstOptionDefault = !!props.isFirstOptionDefault;
    this.setOptions(props.options || []);

    makeObservable(this, {
      debouncedSearchValue: observable,
      isLoading: observable,
      opened: observable,
      isOptionNotListed: observable,
      options: observable.ref,
      close: action.bound,
      open: action.bound,
      allowNewOption: action.bound,
      resetIsOptionNotListed: action.bound,
      searchValue: observable,
      setIsLoading: action.bound,
      setOptions: action.bound,
      setSearchValue: action.bound,
      setDebouncedSearchValue: action.bound,
    });
    const getDebouncedSearchValue = debounce(() => this.setDebouncedSearchValue(this.searchValue), 500);
    reaction(() => this.searchValue, getDebouncedSearchValue);
  }

  get hasChanged(): boolean {
    return this.value.id !== this.defaultValue.id;
  }

  get withSearchbox(): boolean {
    return Boolean(this.props.withSearchbox);
  }

  get localSearchDisabled(): boolean {
    return Boolean(this.props.localSearchDisabled);
  }

  protected get requiredIsValid(): boolean {
    return String(this.value.id).trim().length > 0;
  }

  close(): void {
    this.opened = false;
  }

  open(): void {
    this.opened = true;
  }

  allowNewOption(): void {
    this.isOptionNotListed = true;
  }

  resetIsOptionNotListed(): void {
    this.isOptionNotListed = false;
  }

  setDebouncedSearchValue(value: string): void {
    this.debouncedSearchValue = value;
  }

  setIsLoading(value: boolean): void {
    this.isLoading = value;
  }

  setSearchValue(value: string): void {
    this.searchValue = value;
  }

  setDefaultValueById(optionId: string): void {
    const option = this.options.find(opt =>
      typeof opt.id === 'string' && typeof optionId === 'string'
        ? opt.id.toLowerCase() === optionId.toLowerCase()
        : opt.id === optionId,
    );

    this.setDefaultValue(
      option || {
        id: optionId,
        label: 'Loading...',
      },
    );
  }

  setOptions(options: DropdownOption[]): void {
    this.options = this.props.initialValue?.id ? [getNonRefValue(this.props.initialValue), ...options] : options;
    this.props.withEmptyOption && this.options.splice(0, 0, { id: '', label: this.props.emptyLabel || '--Empty--' });

    // This is needed when dropdown value is set on edit forms before the dropdown options are set.
    if (this.value.id) {
      const option =
        options.find(opt =>
          typeof opt.id === 'string' && typeof this.value.id === 'string'
            ? opt.id.toLowerCase() === this.value.id.toLowerCase()
            : opt.id.toString() === this.value.id.toString(),
        ) ||
        (this.props.initialValue.id === this.value.id || this.value.id === DROPDOWN_ALL_OPTION_ID
          ? this.props.initialValue
          : undefined);
      this.setValue(
        option || {
          ...this.value,
          // label: `[Missing Option; id: ${this.value.id}]`,
        },
      );
    }
  }
}

export const DROPDOWN_BLANK_VALUE: DropdownOption = {
  id: '',
  label: '',
};

// TODO: implement method to specify default options instead of forcing initial value as first value
