import { useQuery } from 'react-query';
import { useAuthContext } from 'src/context/authContext';
import {
  CompetitionFinalLeaderboard,
  CompetitionGame,
  CompetitionLeaderboard,
  CompetitionLeaderboardTeam,
  CompetitionPoolSchedule,
} from 'src/entities/competition/domain';
import { COMPETITIONS_KEYS } from 'src/pages/events/eventPage/competition/queryKeys';
import { apiClient } from 'src/shared/utils/apiClient';
import { ApiError } from 'src/shared/utils/apiError';

interface GetScheduleResponse {
  schedule: {
    created_at: DateTimeString;
    competition_id: number;
    court_number: number;
    event_id: number;
    game_date: DateTimeString;
    game_id: number;
    is_bracket: 1 | 0;
    pool_id: number;
    pool_name: string;
    team_1: number;
    team_2: number;
    team_1_timeout: number;
    team_2_timeout: number;
    team_1_scores: number;
    team_2_scores: number;
    team_1_name: string;
    team_2_name: string;
    match_duration: number;
    timeout_duration: number;
    timeout_per_team: number;
    venue_id: number;
    venue_name: string;
    video_id: number | null;
    video_thumb_url: string | null;
    scores: {
      score_1: number;
      score_2: number;
      quarter_number: number;
    }[];
    stage: string | null;
  }[];
}

async function getSchedule(competitionId: number, token?: AccessToken): Promise<GetScheduleResponse> {
  if (!token) {
    return apiClient(`/v1/pub/competitions/${competitionId}/schedule`);
  }
  return apiClient(`/v1/competitions/${competitionId}/schedule`, { token });
}

export function useCompetitionScheduleQuery(competitionId: number, auth = true) {
  let token: AccessToken | undefined = undefined;
  if (auth) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    token = useAuthContext().token;
  }

  return useQuery<CompetitionPoolSchedule[], ApiError>(
    COMPETITIONS_KEYS.schedule(competitionId),
    async () => {
      const { schedule } = await getSchedule(competitionId, token);
      const gamesPerPool = schedule.reduce((acc, game) => {
        const pool = acc.find(p => p[0].pool_id === game.pool_id);
        if (pool) pool.push(game);
        else acc.push([game]);
        return acc;
      }, [] as GetScheduleResponse['schedule'][]);

      return gamesPerPool.map<CompetitionPoolSchedule>(games => ({
        id: games[0].pool_id,
        name: games[0].pool_name,
        games: games.map(
          game =>
            new CompetitionGame({
              competitionId: game.competition_id,
              stage: game.stage,
              court: game.court_number,
              date: new Date(game.game_date),
              teamAName: game.team_1_name,
              teamBName: game.team_2_name,
              teamATimeout: game.team_1_timeout,
              teamBTimeout: game.team_2_timeout,
              timeoutDuration: game.timeout_duration,
              timeoutPerTeam: game.timeout_per_team,
              matchDuration: game.match_duration,
              teamAId: game.team_1,
              teamBId: game.team_2,
              id: game.game_id,
              teamAScore: game.team_1_scores || 0,
              teamBScore: game.team_2_scores || 0,
              scoreBreakdown: game.scores?.length
                ? game.scores.map(score => ({
                    period: score.quarter_number || 1,
                    teamAScore: score.score_1,
                    teamBScore: score.score_2,
                  }))
                : [{ period: 1, teamAScore: 0, teamBScore: 0 }],
              eventId: game.event_id,
              videoId: game.video_id,
              videoThumbnailUrl: game.video_thumb_url,
            }),
        ),
      }));
    },
    { staleTime: 1000 * 60 * 5 },
  );
}

interface GetLeaderboardResponse {
  schedule: {
    competition_team_id: number;
    competition_team_name: string;
    competition_name: string;
    pool_id: number;
    pool_name: string;
    win_pts: string;
    loss_pts: string;
    draw_pts: string;
    pts_diff: string;
    total: string;
    games_played: number;
    total_score_points: number | null;
  }[];
}

async function getLeaderboard(competitionId: number, token?: AccessToken): Promise<GetLeaderboardResponse> {
  if (!token) {
    return apiClient(`/v1/pub/competitions/${competitionId}/schedule/results`);
  }
  return apiClient(`/v1/competitions/${competitionId}/schedule/results`, { token });
}

export function useLeaderboardQuery(competitionId: number, auth = true) {
  let token: AccessToken | undefined = undefined;
  if (auth) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    token = useAuthContext().token;
  }

  return useQuery<CompetitionLeaderboard, ApiError>(COMPETITIONS_KEYS.leaderboard(competitionId), async () => {
    const { schedule: data } = await getLeaderboard(competitionId, token);

    const leaderboard = data.reduce((acc, pool, id) => {
      const currentPool = acc.find(p => p.poolId === pool.pool_id);
      const currentLeaderboardItem = new CompetitionLeaderboardTeam({
        id,
        poolId: pool.pool_id,
        poolName: pool.pool_name,
        teamId: pool.competition_team_id,
        teamName: pool.competition_team_name,
        losses: Number(pool.loss_pts),
        wins: Number(pool.win_pts),
        points: Number(pool.total),
        pointsDifference: Number(pool.pts_diff),
        draws: Number(pool.draw_pts),
        gamesPlayed: Number(pool.games_played) || 0,
        totalScorePoints: pool.total_score_points,
      });

      if (currentPool) currentPool.teams.push(currentLeaderboardItem);
      else
        acc.push({
          poolId: pool.pool_id,
          poolName: pool.pool_name,
          teams: [currentLeaderboardItem],
        });
      return acc;
    }, [] as CompetitionLeaderboard);

    leaderboard.forEach(({ teams }) =>
      teams.sort((a, b) => {
        if (b.points === a.points) return b.pointsDifference - a.pointsDifference;
        return b.points - a.points;
      }),
    );
    return leaderboard;
  });
}

interface GetFinalLeaderboardResponse {
  schedule: {
    team_id: number;
    competition_team_name: string;
    competition_team_id: number;
    competition_name: string;
    games_played: string;
    win_pts: string;
    loss_pts: string;
    draw_pts: string;
    pts_diff: string;
    total: string;
    total_score_points: number | null;
  }[];
}

async function getFinalLeaderboard(competitionId: number, token?: AccessToken): Promise<GetFinalLeaderboardResponse> {
  if (!token) {
    return apiClient(`/v1/pub/competitions/${competitionId}/results/all`);
  }
  return apiClient(`/v1/competitions/${competitionId}/results/all`, { token });
}

export function useFinalLeaderboardQuery(competitionId: number, auth = true) {
  let token: AccessToken | undefined = undefined;
  if (auth) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    token = useAuthContext().token;
  }

  return useQuery<CompetitionFinalLeaderboard[], ApiError>(
    COMPETITIONS_KEYS.finalLeaderboard(competitionId),
    async () => {
      const { schedule: data } = await getFinalLeaderboard(competitionId, token);

      const leaderboard = data.map(item => ({
        id: item.team_id,
        teamName: item.competition_team_name,
        teamId: item.competition_team_id,
        competitionName: item.competition_name,
        points: Number(item.total),
        wins: Number(item.win_pts),
        losses: Number(item.loss_pts),
        draws: Number(item.draw_pts),
        gamesPlayed: Number(item.games_played) || 0,
        pointsDifference: Number(item.pts_diff),
        totalScorePoints: item.total_score_points,
      }));
      return leaderboard;
    },
  );
}
