import { apiClient } from 'src/shared/utils/apiClient';
import { PlayerData } from './model';

interface AddTeamPlayersRequest {
  teamId: number;
  playersData: PlayerData[];
  sendWelcomeEmail: boolean;
}

export async function addTeamPlayers(request: AddTeamPlayersRequest, token: AccessToken): Promise<void> {
  return apiClient(`/v1/teams/${request.teamId}/add/players`, {
    method: 'POST',
    token,
    body: JSON.stringify({
      send_welcome_email_flag: JSON.stringify(request.sendWelcomeEmail ? 1 : 0),
      players_data: JSON.stringify(request.playersData),
    }),
  });
}
