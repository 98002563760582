import { reaction } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useLYGBoroughsQuery } from 'src/lyg/entities/boroughs/query';
import { DropdownField } from 'src/shared/ui/inputs/dropdown/DropdownField';
import { DROPDOWN_BLANK_VALUE, DropdownFieldModel } from 'src/shared/ui/inputs/dropdown/DropdownFieldModel';
import { QueryLoader } from 'src/shared/ui/utils/QueryLoader';

interface BoroughPickerProps {
  model: DropdownFieldModel;
  className?: string;
}

export const BoroughPicker: React.FC<BoroughPickerProps> = ({ model, className }) => {
  const query = useLYGBoroughsQuery();

  return (
    <QueryLoader query={query}>
      {({ data }) => {
        model.setOptions(
          data.map(({ id, name }) => ({
            id: id.toString(),
            label: name,
          })),
        );
        return <DropdownField model={model} className={className} />;
      }}
    </QueryLoader>
  );
};

export function createBoroughPickerDropdown(props: RequireMinOne<Partial<DropdownFieldModel['props']>, 'i18n'>) {
  return new DropdownFieldModel({
    id: 'borough',
    initialValue: props.initialValue ?? DROPDOWN_BLANK_VALUE,
    title: 'Borough',
    withSearchbox: true,
    localSearchDisabled: false,
    withEmptyOption: false,
    required: true,
    ...props,
    i18n: props.i18n,
  });
}
