import React from 'react';
import { Seed, SeedItem, SeedTeam, IRenderSeedProps } from 'react-brackets';
import { Edit2, Play } from 'react-feather';
import { PrimaryInvertedButton } from 'src/shared/ui/buttons';
import { cx } from 'src/shared/utils/common';
import { THEME_COLORS } from 'src/constants';
import css from './BracketMatch.module.scss';
import { CompetitionGame, CompetitionGameStatus } from 'src/entities/competition/domain';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppRoutesContext } from 'src/context/routes';
import { VideoThumbnailLink } from 'src/pages/videos/home/ui/VideoThumbnailLink';
import { TSVideo } from 'src/entities/videos/domain';
interface TeamItemProps {
  name: string | undefined;
  score: number | undefined;
  status: CompetitionGameStatus;
  isDummyGame?: boolean;
}

const TeamItem: React.FC<TeamItemProps> = ({ name, score, status, isDummyGame = false }) => {
  return (
    <SeedTeam
      style={{
        justifyContent: 'space-between',
        gap: '18px',
        height: 50,
        backgroundColor: !name
          ? 'white'
          : isDummyGame
          ? 'lightgray'
          : status === CompetitionGame.Status.win
          ? THEME_COLORS.fourth
          : status === CompetitionGame.Status.lose
          ? THEME_COLORS.first
          : THEME_COLORS.third,
      }}
    >
      <span className='text-start'>{name || 'TBD '}</span>
      {!isDummyGame && <span>{score || 0}</span>}
    </SeedTeam>
  );
};

interface BracketMatch extends IRenderSeedProps {
  disableActions?: boolean;
  onEditScore(game: CompetitionGame): void;
  onEditDate(game: CompetitionGame): void;
}

export const BracketMatch: React.FC<BracketMatch> = ({ seed, breakpoint, disableActions, onEditScore, onEditDate }) => {
  const navigate = useNavigate();
  const { eventId } = useParams();
  const routes = useAppRoutesContext();
  const game = seed.game as CompetitionGame;
  const isDummyGame = seed.teams[0]?.name === 'Seeded' || seed.teams[1]?.name === 'Seeded';

  return (
    <Seed mobileBreakpoint={breakpoint}>
      <SeedItem
        style={{
          backgroundColor: 'white',
          color: 'black',
          borderRadius: '10px',
          overflow: 'hidden',
        }}
      >
        <button
          className={cx(css.match, 'p-0 w-100')}
          onClick={() => !disableActions && onEditScore(game)}
          disabled={!seed.teams[0].name || !seed.teams[1].name}
          style={isDummyGame ? { cursor: 'not-allowed' } : {}}
        >
          <TeamItem
            name={seed.teams[0]?.name}
            score={seed.teams[0]?.score}
            status={seed.teams[0]?.status}
            isDummyGame={isDummyGame}
          />
          <div className='ts-h-divider w-100' />
          <TeamItem
            name={seed.teams[1]?.name}
            score={seed.teams[1]?.score}
            status={seed.teams[1]?.status}
            isDummyGame={isDummyGame}
          />
        </button>
      </SeedItem>

      {!isDummyGame ? (
        <p className='mt-2 mb-0 d-flex flex-column w-100 ps-1'>
          {seed.date}
          <span className='d-flex gap-0 align-items-center'>
            <PrimaryInvertedButton onClick={() => !disableActions && onEditDate(game)} loading={disableActions}>
              <Edit2 className='align-middle' />
            </PrimaryInvertedButton>
            <PrimaryInvertedButton
              onClick={() =>
                navigate(`${routes.competitions.liveEditor(Number(eventId), game.competitionId, game.id)}?from=bracket`)
              }
              loading={disableActions}
            >
              <Play className='align-middle' />
            </PrimaryInvertedButton>
            {game.videoThumbnailUrl && !disableActions && (
              <VideoThumbnailLink
                video={
                  {
                    id: game.videoId,
                    thumbnailUrl: `https://videos.teamsportz.pro${game.videoThumbnailUrl}`,
                  } as unknown as TSVideo
                }
                style={{ height: '18px', borderRadius: '100%', width: '18px' }}
                isSmall
              />
            )}
          </span>
        </p>
      ) : (
        // To avoid layout shift
        <div style={{ height: '52px' }} />
      )}
    </Seed>
  );
};
