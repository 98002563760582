import React from 'react';
import ReactDOM from 'react-dom';
import { MoreVertical } from 'react-feather';
import { useI18nContext } from 'src/context/i18n';
import { useOnClickOutside } from 'src/shared/hooks/useOnClickOutside';
import { cx } from 'src/shared/utils/common';
import css from './DropdownButton.module.scss';

interface DropdownButtonOption {
  label: JSX.Element | string;
  disabled?: boolean;
  onClick(): void;
}

interface DropdownButtonProps {
  options: (DropdownButtonOption | false)[];
  isFullSize?: boolean;
  triggerParent?: boolean;
}

function getMenuPosition(buttonRef: HTMLDivElement, menuWidth: number): { left: number; top: number } {
  const btnBound = buttonRef.getBoundingClientRect();

  const offset = { left: menuWidth - btnBound.width, top: -btnBound.height - window.scrollY };
  offset.left += 10;

  return { left: btnBound.left - offset.left, top: btnBound.top - offset.top };
}

export const DropdownButton: React.FC<DropdownButtonProps> = ({
  options,
  children,
  isFullSize = false,
  triggerParent = false,
}) => {
  const i18n = useI18nContext();
  const [opened, setOpened] = React.useState(false);
  const [wrapperRef, setWrapperRef] = React.useState<HTMLDivElement | null>(null);
  const btnRef = useOnClickOutside(opened, () => setOpened(false), [wrapperRef]);

  function setPosition(menuRef: HTMLDivElement | null) {
    if (!menuRef || !btnRef.current) return;
    const position = getMenuPosition(btnRef.current, menuRef.getBoundingClientRect().width);
    menuRef.style.top = `${position.top + 10}px`;
    menuRef.style.left = `${position.left + 4}px`;
  }

  const hasOptions = React.useMemo(() => options.filter(Boolean).length > 0, [options]);

  return (
    <div className={`${triggerParent ? 'w-100 h-100 position-absolute' : 'position-relative'}`} ref={btnRef}>
      {triggerParent ? (
        <div
          className={'w-100 h-100 p-0 bg-transparent opacity-0'}
          onClick={() => setOpened(isOpened => !isOpened)}
          onKeyDown={e => e.key === 'Enter' && setOpened(isOpened => !isOpened)}
          role='button'
          tabIndex={0}
        >
          {children || <MoreVertical className={cx(!isFullSize ? css.triggerIcon : '')} />}
        </div>
      ) : (
        <button
          className='w-100 h-100 p-0 bg-transparent'
          type='button'
          onClick={() => setOpened(isOpened => !isOpened)}
        >
          {children || <MoreVertical className={cx(!isFullSize ? css.triggerIcon : '')} />}
        </button>
      )}
      {opened &&
        ReactDOM.createPortal(
          <div
            className={cx('position-absolute', css.dropdownPanel)}
            ref={ref => {
              setWrapperRef(ref);
              setPosition(ref);
            }}
          >
            {hasOptions ? (
              options.filter(Boolean).map((opt, index) => {
                const option = opt as DropdownButtonOption;
                return (
                  <React.Fragment key={index}>
                    <button
                      className={cx(
                        'w-100 px-3 text-nowrap ts-fw-500 ts-fs-13 d-flex',
                        css.dropdownItem,
                        option.disabled && css.disabled,
                      )}
                      type='button'
                      onClick={() => {
                        option.onClick();
                        setOpened(false);
                      }}
                      disabled={option.disabled}
                    >
                      {option.label}
                    </button>
                    {index !== options.length - 1 && <div className={css.separator} />}
                  </React.Fragment>
                );
              })
            ) : (
              <span className={cx('w-100 px-3 text-nowrap ts-fw-500 ts-fs-13 d-flex', css.dropdownItem)}>
                {i18n.t('shared.phrases.noOptions')}
              </span>
            )}
          </div>,
          document.getElementById('root') as Element,
        )}
    </div>
  );
};
