import { I18nModel } from 'src/context/i18n/I18nModel';
import { PlayerGuardian } from 'src/pages/players/edit/PlayerGuardianModel';
import { stringFromJoinedParts } from 'src/shared/utils/strings';

export enum PLAYER_AGE_GROUP {
  masters = 'Masters',
  senior = 'Senior',
  u13 = 'U13',
  u14 = 'U14',
  u15 = 'U15',
  u16 = 'U16',
  u17 = 'U17',
  u18 = 'U18',
  u19 = 'U19',
  u20 = 'U20',
}

export enum PLAYER_FITNESS {
  beginner = 'Beginner',
  intermediate = 'Intermediate',
  advanced = 'Advanced',
}

export enum PLAYER_GENDER {
  female = 'Female',
  male = 'Male',
  nonBinary = 'Non-Binary',
}

export enum PLAYER_METRIC_DISTANCE {
  centimeters = 'centimeters',
  feet = 'feet',
}

export enum PLAYER_METRIC_WEIGHT {
  kilograms = 'kilograms',
  stone = 'stone',
}

export enum BASKETBALL_POSITION {
  center = 'C',
  forward = 'F',
  guard = 'G',
  forwardOrGuard = 'FG',
  forwardCenter = 'FC',
  guardOrForward = 'GF',
  powerForward = 'PF',
  pointGuard = 'PG',
  pointOrForward = 'FG',
  pointOrGuard = 'FG',
  smallForward = 'SF',
  shootingGuard = 'SG',
  wing = 'W',
}

export enum FOOTBALL_POSITION {
  goalkeeper = 'GK',
  rightFullback = 'RB',
  leftFullback = 'LF',
  centerBack = 'CB',
  defendingMidfielder = 'CDM',
  rightMidfielder = 'RM',
  centralMidfielder = 'CM',
  striker = 'S',
  attackingMidfielder = 'AM',
  leftMidfielder = 'LM',
  attackingMidfield = 'CAM',
  leftWingBack = 'LWB',
  rightWingBack = 'RWB',
}

export type PLAYER_POSITION = FOOTBALL_POSITION | BASKETBALL_POSITION | string;

export enum PLAYER_SKILL {
  beginner = 'Beginner',
  intermediate = 'Intermediate',
  advanced = 'Advanced',
}

// TODO: discuss with API devs about how user sport string needs to match workout name
// If they match, then the sport field options should probably be pulled from the workouts API
export enum PLAYER_SPORT {
  americanFootball = 'American Football',
  basketball = 'Basketball',
  conditioning = 'Conditioning & Fitness',
  football = 'Football',
  netball = 'Netball',
  rugby = 'Rugby',
  tennis = 'Tennis',
}

interface SimpleContactInfo {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

export interface Player {
  ageGroup?: PLAYER_AGE_GROUP;
  avatarUrl?: string;
  bio?: string;
  city?: string;
  created?: Date;
  email: string;
  dateOfBirth?: Date;
  debuggingEnabled: boolean;
  fitness?: PLAYER_FITNESS;
  gender?: PLAYER_GENDER;
  height?: number;
  firstName: string;
  firstLogin?: Date;
  id: number;
  jerseyNumber: number | null;
  lastExercised?: Date;
  lastLogin?: Date;
  lastName: string;
  metricDistance?: PLAYER_METRIC_DISTANCE;
  metricWeight?: PLAYER_METRIC_WEIGHT;
  onboardingEnabled: boolean;
  onboardingCoachEnabled: boolean;
  clubId?: number;
  clubName?: string;
  phoneNumber?: string;
  position: PLAYER_POSITION | null;
  registrationNumber: string | null;
  registrationFileUrl: string | null;
  role: string;
  jobTitle: string;
  rating: number;
  skeleton: boolean;
  skill?: PLAYER_SKILL;
  sport?: PLAYER_SPORT;
  teamId?: number;
  teamName?: string;
  weight?: number;
  guardians: PlayerGuardian[];
  isConsentGiven: boolean;
  isRecordingConsentGiven: boolean;
  hasHealthCondition: boolean;
  consentGivenBy: string;
  consentGivenDate: Date | null;
  consentDataNotice: boolean;
  consentDataCollection: boolean;
  consentSharingInformation: boolean;
  consentDataHandling: boolean;
  consentTermsAndConditions: boolean;
  consentClubCodeOfConduct: boolean;
  consentEmergencyTreatment: boolean;
  consentCoachCodeOfConduct: boolean;
  consentParentCodeOfConduct: boolean;
  consentPlayerCodeOfConduct: boolean;
  parent?: SimpleContactInfo[] | null;
  healthCondition: string;
}

export type PlayerSimple = Pick<
  Player,
  | 'id'
  | 'avatarUrl'
  | 'firstName'
  | 'lastName'
  | 'teamId'
  | 'jerseyNumber'
  | 'position'
  | 'rating'
  | 'registrationNumber'
  | 'registrationFileUrl'
  | 'isConsentGiven'
  | 'isRecordingConsentGiven'
  | 'hasHealthCondition'
>;

export function getPlayerNameWithLastNameInitial(player: Pick<Player, 'firstName' | 'lastName'>) {
  return `${player.firstName}${player.lastName ? ` ${player.lastName?.substring(0, 1)}.` : ''}`;
}

export function getPlayerFullName(player: Pick<Player, 'firstName' | 'lastName'>): string {
  return stringFromJoinedParts([player.firstName, player.lastName], ' ') || '[missing player name]';
}

export function getPlayerMetricDistanceAbbreviation(player: Player, i18n: I18nModel): string {
  if (player.metricDistance) {
    return player.metricDistance === PLAYER_METRIC_DISTANCE.centimeters ? 'cm' : 'ft';
  }
  return i18n.t('region.distanceUnit');
}

export function getPlayerMetricWeightAbbreviation(player: Player, i18n: I18nModel): string {
  if (player.metricWeight) {
    return player.metricWeight === PLAYER_METRIC_WEIGHT.kilograms ? 'kg' : 'st';
  }
  return i18n.t('region.distanceUnit');
}

export interface PlayerIndicatorRating {
  id: number;
  name: string;
  rating: number;
}

export interface TeamPlayersAverageIndicatorRating {
  id: number;
  name: string;
  rating: number;
}
