import React from 'react';
import { cx } from 'src/shared/utils/common';
import { abbreviateString } from 'src/shared/utils/strings';
import css from './RoundedImage.module.scss';

interface RoundedImageProps {
  className?: string;
  name: string;
  url?: string | null;
  withBorder?: boolean;
  smallAvatar?: boolean;
}

export const RoundedImage: React.FC<RoundedImageProps> = ({ className, name, url, withBorder, smallAvatar }) => {
  return (
    <div
      aria-label={name}
      className={cx(
        'd-flex align-items-center justify-content-center rounded-circle flex-shrink-0',
        css.avatar,
        url && css.hasUrl,
        withBorder && css.withBorder,
        smallAvatar && css.small,
        className,
      )}
    >
      {url ? (
        <img
          alt={name}
          className='h-100 mh-100 w-100 mw-100 rounded-circle'
          /* crossOrigin='anonymous' */
          referrerPolicy='no-referrer'
          src={url}
        />
      ) : (
        <span className={smallAvatar ? 'ts-fs-14' : ''}>{abbreviateString(name || '', 2)}</span>
      )}
    </div>
  );
};
