import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { useAuthContext } from 'src/context/authContext';
import { TEAM_QUERY_KEYS } from 'src/entities/teams/queries/queryKeys';
import { addTeamPlayers } from './api';
import { CreateTeamPlayerModel } from './model';

export function useAddTeamPlayersMutation(
  model: CreateTeamPlayerModel,
  teamId: number,
  onSuccess?: () => void,
): UseMutationResult<void, Error, void> {
  const { token } = useAuthContext();
  const queryClient = useQueryClient();

  return useMutation<void, Error, void>(
    () =>
      addTeamPlayers(
        {
          teamId,
          playersData: model.data,
          sendWelcomeEmail: model.notifyPlayersField.value,
        },
        token,
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(TEAM_QUERY_KEYS.base);
        onSuccess?.();
      },
    },
  );
}
