import { useQuery } from 'react-query';
import { useAuthContext } from 'src/context/authContext';
import { CompetitionFinalLeaderboard } from 'src/entities/competition/domain';
import { LYGCompetitionGame, LYGCompetitionPoolSchedule } from 'src/lyg/entities/competitions/LYGCompetitionGame';
import {
  LYGCompetitionFinalLeaderboard,
  LYGCompetitionLeaderboard,
  LYGCompetitionLeaderboardTeam,
} from 'src/lyg/entities/competitions/LYGCompetitionLeaderboard';
import { LYG_COMPETITIONS_KEYS } from 'src/lyg/pages/competitions/queryKeys';
import { apiClient } from 'src/lyg/utils/apiClient';
import { ApiError } from 'src/lyg/utils/apiError';

interface GetScheduleResponse {
  schedule: {
    created_at: DateTimeString;
    lyg_competition_id: number;
    lyg_court_number: number;
    lyg_event_id: number;
    lyg_game_date: DateTimeString;
    lyg_game_id: number;
    lyg_is_bracket: 1 | 0;
    lyg_pool_id: number;
    lyg_pool_name: string;
    lyg_team_1: number;
    lyg_team_2: number;
    team_1_scores: number;
    team_2_scores: number;
    team_1_name: string;
    team_2_name: string;
    venue_id: number;
    venue_name: string;
    scores: {
      lyg_score_1: number;
      lyg_score_2: number;
      lyg_quarter_number: number;
    }[];
  }[];
}

async function getSchedule(competitionId: number, token?: AccessToken): Promise<GetScheduleResponse> {
  return apiClient(`/v1/lyg/competitions/${competitionId}/schedule`, { token });
}

export function useLYGCompetitionScheduleQuery(competitionId: number, auth = true) {
  let token: AccessToken | undefined = undefined;
  if (auth) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    token = useAuthContext().token;
  }

  return useQuery<LYGCompetitionPoolSchedule[], ApiError>(
    LYG_COMPETITIONS_KEYS.schedule(competitionId),
    async () => {
      const { schedule } = await getSchedule(competitionId, token);
      const gamesPerPool = schedule.reduce((acc, game) => {
        const pool = acc.find(p => p[0].lyg_pool_id === game.lyg_pool_id);
        if (pool) pool.push(game);
        else acc.push([game]);
        return acc;
      }, [] as GetScheduleResponse['schedule'][]);

      return gamesPerPool.map<LYGCompetitionPoolSchedule>(games => ({
        id: games[0].lyg_pool_id,
        name: games[0].lyg_pool_name,
        games: games.map(
          game =>
            new LYGCompetitionGame({
              competitionId: game.lyg_competition_id,
              court: game.lyg_court_number,
              date: new Date(game.lyg_game_date),
              teamAName: game.team_1_name,
              teamBName: game.team_2_name,
              teamAId: game.lyg_team_1,
              teamBId: game.lyg_team_2,
              id: game.lyg_game_id,
              teamAScore: game.team_1_scores || 0,
              teamBScore: game.team_2_scores || 0,
              scoreBreakdown: game.scores.length
                ? game.scores.map(score => ({
                    period: score.lyg_quarter_number || 1,
                    teamAScore: score.lyg_score_1,
                    teamBScore: score.lyg_score_2,
                  }))
                : [{ period: 1, teamAScore: 0, teamBScore: 0 }],
            }),
        ),
      }));
    },
    { staleTime: 1000 * 60 * 5 },
  );
}

interface GetLeaderboardResponse {
  schedule: {
    lyg_team_id: number;
    lyg_team_name: string;
    lyg_competition_name: string;
    lyg_pool_id: number;
    lyg_pool_name: string;
    win_pts: string;
    loss_pts: string;
    draw_pts: string;
    pts_diff: string;
    total: string;
    games_played: number;
    total_score_points: number | null;
  }[];
}

async function getLeaderboard(competitionId: number, token?: AccessToken): Promise<GetLeaderboardResponse> {
  return apiClient(`/v1/lyg/competitions/${competitionId}/schedule/results`, { token });
}

export function useLYGLeaderboardQuery(competitionId: number, auth = true) {
  let token: AccessToken | undefined = undefined;
  if (auth) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    token = useAuthContext().token;
  }

  return useQuery<LYGCompetitionLeaderboard, ApiError>(LYG_COMPETITIONS_KEYS.leaderboard(competitionId), async () => {
    const { schedule: data } = await getLeaderboard(competitionId, token);

    const leaderboard = data.reduce((acc, pool, id) => {
      const currentPool = acc.find(p => p.poolId === pool.lyg_pool_id);
      const currentLeaderboardItem = new LYGCompetitionLeaderboardTeam({
        id,
        poolId: pool.lyg_pool_id,
        poolName: pool.lyg_pool_name,
        teamId: pool.lyg_team_id,
        teamName: pool.lyg_team_name,
        losses: Number(pool.loss_pts),
        wins: Number(pool.win_pts),
        points: Number(pool.total),
        pointsDifference: Number(pool.pts_diff),
        draws: Number(pool.draw_pts),
        gamesPlayed: Number(pool.games_played) || 0,
        totalScorePoints: Number(pool.total_score_points) || 0,
      });

      if (currentPool) currentPool.teams.push(currentLeaderboardItem);
      else
        acc.push({
          poolId: pool.lyg_pool_id,
          poolName: pool.lyg_pool_name,
          teams: [currentLeaderboardItem],
        });
      return acc;
    }, [] as LYGCompetitionLeaderboard);

    leaderboard.forEach(({ teams }) =>
      teams.sort((a, b) => {
        if (b.points === a.points) return b.pointsDifference - a.pointsDifference;
        return b.points - a.points;
      }),
    );
    return leaderboard;
  });
}

interface GetLYGFinalLeaderboardResponse {
  schedule: {
    lyg_team_id: number;
    lyg_team_name: string;
    lyg_competition_id: number;
    lyg_competition_name: string;
    games_played: string;
    win_pts: string;
    loss_pts: string;
    draw_pts: string;
    pts_diff: string;
    total: string;
    total_score_points: number | null;
  }[];
}

async function getLYGFinalLeaderboard(
  competitionId: number,
  token?: AccessToken,
): Promise<GetLYGFinalLeaderboardResponse> {
  // if (!token) {
  //   return apiClient(`/v1/pub/lyg/competitions/${competitionId}/results/all`);
  // }
  return apiClient(`/v1/lyg/competitions/${competitionId}/results/all`, { token });
}

export function useLYGFinalLeaderboardQuery(competitionId: number, auth = true) {
  let token: AccessToken | undefined = undefined;
  if (auth) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    token = useAuthContext().token;
  }

  return useQuery<LYGCompetitionFinalLeaderboard[], ApiError>(
    LYG_COMPETITIONS_KEYS.finalLeaderboard(competitionId),
    async () => {
      const { schedule } = await getLYGFinalLeaderboard(competitionId, token);

      const leaderboard = schedule.map(item => ({
        id: item.lyg_team_id,
        teamId: item.lyg_team_id,
        teamName: item.lyg_team_name,
        competitionId: item.lyg_competition_id,
        competitionName: item.lyg_competition_name,
        gamesPlayed: Number(item.games_played) || 0,
        points: Number(item.total),
        wins: Number(item.win_pts),
        losses: Number(item.loss_pts),
        draws: Number(item.draw_pts),
        pointsDifference: Number(item.pts_diff),
        totalScorePoints: item.total_score_points,
      }));
      return leaderboard;
    },
  );
}
