import { AquathlonEntry } from 'src/lyg/entities/entries/aquathlonEntry';
import { AthleticsEntry } from 'src/lyg/entities/entries/athleticsEntry';
import { BmxEntry } from 'src/lyg/entities/entries/bmxEntry';
import { CrossCountryEntry } from 'src/lyg/entities/entries/crossCountryEntry';
import { IndoorRowingEntry } from 'src/lyg/entities/entries/indoorRowingEntry';
import { JudoEntry } from 'src/lyg/entities/entries/judoEntry';
import { KayakSlalomEntry } from 'src/lyg/entities/entries/kayakSlalomEntry';
import { KayakSprintEntry } from 'src/lyg/entities/entries/kayakSprintEntry';
import { PgAthleticsEntry } from 'src/lyg/entities/entries/pgAthleticsEntry';
import { PgSwimmingEntry } from 'src/lyg/entities/entries/pgSwimmingEntry';
import { RoadCyclingEntry } from 'src/lyg/entities/entries/roadCyclingEntry';
import { SwimmingCompetitiveEntry } from 'src/lyg/entities/entries/swimmingCompetitive';
import { SwimmingEntry } from 'src/lyg/entities/entries/swimmingEntry';
import { SwimmingRecreationalEntry } from 'src/lyg/entities/entries/swimmingRecreationalEntry';
import { TennisEntry } from 'src/lyg/entities/entries/tennisEntry';
import { TrampolineEntry } from 'src/lyg/entities/entries/trampolineEntry';

export interface LYGEntry {
  id: number;
  name: string;
  gender: string;
  email: string | null;
  parentName: string | null;
  parentEmail: string | null;
  dob: Date;
  boroughName: string;
  boroughId: number;
  ethnicity: string;
  disability: string;
  homePostcode: string;
  postcodeChecked: number;
  postcodePrimaryCareTrust: string;
  postcodeStatus: string;
  school: string;
  emergencyContactName: string;
  emergencyContactNumber: string;
  imageryConscent: boolean;
  competitionName: string | null;
  aquathlon: AquathlonEntry | null;
  athletics: AthleticsEntry | null;
  bmx: BmxEntry | null;
  crossCountry: CrossCountryEntry | null;
  indoorRowing: IndoorRowingEntry | null;
  judo: JudoEntry | null;
  kayakSlalom: KayakSlalomEntry | null;
  kayakSprint: KayakSprintEntry | null;
  pgAthletics: PgAthleticsEntry | null;
  pgSwimming: PgSwimmingEntry | null;
  roadCycling: RoadCyclingEntry | null;
  swimming: SwimmingEntry | null;
  tennis: TennisEntry | null;
  trampoline: TrampolineEntry | null;
  swimmingRecreational: SwimmingRecreationalEntry | null;
  swimmingCompetitive: SwimmingCompetitiveEntry | null;
  presence: boolean;
  teamManagerFirstName: string | null;
  teamManagerLastName: string | null;
  teamManagerEmail: string | null;
  codeOfConductConsent: boolean;
}

export class LYGEntry {
  public constructor(params: OnlyData<LYGEntry>) {
    Object.assign(this, params);
  }
}
