import { apiClient } from 'src/shared/utils/apiClient';

export interface GetTeamResponseItem {
  coach1: string | null;
  coach2: string | null;
  coach3: string | null;
  team_coach1_role: string | null;
  team_coach2_role: string | null;
  team_coach3_role: string | null;
  team_division: string | null;
  team_uk_basketball_be_league: string | null;
  team_uk_basketball_be_div_stage: string | null;
  league_id: number | null;
  coaches: {
    profile_first_name: string;
    profile_id: number;
    profile_last_name: string;
    profile_number: number | null;
    profile_position: string | null;
    profile_reg_number: string | null;
    profile_reg_file: string | null;
    profile_avatar: string | null;
    profile_telephone1: string | null;
    email: string;
  }[][];
  head_coach: {
    profile_first_name: string;
    profile_id: number;
    profile_last_name: string;
    profile_number: number | null;
    profile_position: string | null;
    profile_reg_number: string | null;
    profile_reg_file: string | null;
    profile_avatar: string | null;
    profile_telephone1: string | null;
    email: string;
  }[];
  team_avatar: string | null;
  team_code: string;
  team_description: string | null;
  team_id: number;
  team_name: string;
  team_tags: string | null;
  org_id: number;
  org_name: string;
  player_count: number;
  created_at: DateTimeString;
  created_by: number;
  team_venues: [
    {
      venue_id: number;
      venue_city: string | null;
      venue_country_code: string | null;
      venue_country_name: string | null;
      venue_address1: string | null;
      venue_full_address: string | null;
      venue_lat: string | null;
      venue_lat_delta: string | null;
      venue_lng: string | null;
      venue_lng_delta: string | null;
      venue_northeast_lat: string | null;
      venue_northeast_lng: string | null;
      venue_postcode: string | null;
      venue_southwest_lat: string | null;
      venue_southwest_lng: string | null;
      venue_state_province: string | null;
      venue_street_name: string | null;
      venue_street_number: string | null;
      venue_name: string | null;
    },
  ];
}

export function getTeam(teamId: number, token: AccessToken): Promise<GetTeamResponseItem[]> {
  return apiClient(`/v1/teams/${teamId}`, { token });
}
