import React from 'react';
import { Bracket } from 'react-brackets';
import { useNavigate } from 'react-router-dom';
import { useAppRoutesContext } from 'src/context/routes';
import { Competition, CompetitionBracket, CompetitionGame } from 'src/entities/competition/domain';
import { BracketMatch } from 'src/pages/competitions/bracket/components/BracketMatch';
import { EditGameDataModal } from 'src/pages/competitions/bracket/components/EditGameDataModal';

interface BracketProps {
  bracket: CompetitionBracket;
  competition: Competition | undefined;
}

export const BracketComp: React.FC<BracketProps> = ({ competition, bracket }) => {
  const [editDateGame, setEditDateGame] = React.useState<CompetitionGame | null>(null);
  const navigate = useNavigate();
  const routes = useAppRoutesContext();

  const onEdit = (game: CompetitionGame) => {
    if (game.id < 1) return;
    navigate(
      `${routes.competitions.game(Number(competition?.eventId), Number(competition?.id), game.id)}?from=bracket`,
    );
  };

  return (
    <>
      <Bracket
        renderSeedComponent={props => (
          <BracketMatch {...props} onEditDate={setEditDateGame} onEditScore={onEdit} disableActions={!competition} />
        )}
        rounds={bracket.getRenderData()}
      />
      {editDateGame && competition && (
        <EditGameDataModal closeDialog={() => setEditDateGame(null)} game={editDateGame} />
      )}
    </>
  );
};
