import React, { useState } from 'react';
import { CompetitionFinalLeaderboard, CompetitionLeaderboardTeam } from 'src/entities/competition/domain';
import { Accordion } from 'src/features/accordion/Accordion';
import { useFinalLeaderboardQuery, useLeaderboardQuery } from 'src/pages/competitions/schedule/query';
import { DataTable } from 'src/shared/ui/data/DataTable';
import { DataTableModel } from 'src/shared/ui/data/DataTableModel';
import { QueryLoader } from 'src/shared/ui/utils/QueryLoader';
import { DataTableItemDialog } from 'src/shared/ui/data/DataTableItemDialog';
import { CSS_BREAKPOINTS } from 'src/constants';

interface PoolLeaderboardProps {
  teams: CompetitionLeaderboardTeam[];
  searchTerm: string;
}

const filterTeam = (data: CompetitionLeaderboardTeam[], searchStr: string) => {
  if (!isNaN(+searchStr)) return data;
  return data.filter(item => item.teamName.toLowerCase().includes(searchStr.toLowerCase()));
};

const PoolLeaderboard: React.FC<PoolLeaderboardProps> = ({ teams, searchTerm }) => {
  const [model] = useState(
    new DataTableModel<CompetitionLeaderboardTeam>({
      columns: [
        {
          className: 'flex-shrink-0 flex-grow-1 ts-text-overflow',
          width: 180,
          key: 'team',
          title: 'Team',
          dataCell: ({ dataItem }) => <span>{dataItem.teamName}</span>,
        },
        {
          className: 'flex-shrink-0',
          width: 40,
          key: 'played',
          title: 'Played',
          dataCell: ({ dataItem }) => <span>{dataItem.gamesPlayed}</span>,
          overflowBreakpoint: CSS_BREAKPOINTS.md,
        },
        {
          className: 'flex-shrink-0',
          width: 40,
          key: 'wins',
          title: 'Wins',
          dataCell: ({ dataItem }) => <span>{dataItem.wins}</span>,
          overflowBreakpoint: CSS_BREAKPOINTS.md,
        },
        {
          className: 'flex-shrink-0',
          width: 40,
          key: 'ties',
          title: 'Ties',
          dataCell: ({ dataItem }) => <span>{dataItem.draws}</span>,
          overflowBreakpoint: CSS_BREAKPOINTS.md,
        },
        {
          className: 'flex-shrink-0',
          width: 40,
          key: 'losses',
          title: 'Losses',
          dataCell: ({ dataItem }) => <span>{dataItem.losses}</span>,
          overflowBreakpoint: CSS_BREAKPOINTS.md,
        },
        {
          className: 'flex-shrink-0',
          width: 50,
          key: 'pd',
          title: 'PD',
          dataCell: ({ dataItem }) => <span>{dataItem.pointsDifference}</span>,
          overflowBreakpoint: CSS_BREAKPOINTS.md,
        },
        {
          className: 'flex-shrink-0',
          width: 40,
          key: 'points',
          title: 'Points',
          dataCell: ({ dataItem }) => <span>{dataItem.points}</span>,
        },
        {
          className: 'flex-shrink-0',
          width: 50,
          key: 'totalScorePoints',
          title: 'TSP',
          dataCell: ({ dataItem }) => <span>{dataItem.totalScorePoints || 0}</span>,
          overflowBreakpoint: CSS_BREAKPOINTS.md,
        },
        {
          className: 'flex-shrink-0 flex-grow-0',
          key: 'actions',
          width: 20,
          dataCell: ({ dataItem }) => {
            if (model.hasHiddenColumns) {
              return (
                <DataTableItemDialog
                  columns={model.columns}
                  dialogTitle={dataItem.teamName}
                  dataItem={dataItem}
                  displayOnlyHiddenColumns
                />
              );
            }
            return null;
          },
          dialogDataCell: () => null,
        },
      ],
      onSort: () => null,
    }),
  );

  return (
    <div>
      <div>
        <DataTable headerClassName='ts-fw-500' data={filterTeam(teams, searchTerm)} model={model} />
      </div>
    </div>
  );
};

interface FinalLeaderboardProps {
  finalLeaderboard: CompetitionFinalLeaderboard[];
  isPublic?: boolean;
}

export const FinalLeaderboard: React.FC<FinalLeaderboardProps> = ({ finalLeaderboard, isPublic = false }) => {
  const [model] = useState(
    new DataTableModel<CompetitionFinalLeaderboard>({
      columns: [
        {
          className: 'flex-shrink-0 flex-grow-1 ts-text-overflow',
          width: 180,
          key: 'team',
          title: 'Team',
          dataCell: ({ dataItem }) => <span>{dataItem.teamName}</span>,
        },
        {
          className: 'flex-shrink-0',
          width: 40,
          key: 'played',
          title: 'Played',
          dataCell: ({ dataItem }) => <span>{dataItem.gamesPlayed}</span>,
          overflowBreakpoint: CSS_BREAKPOINTS.md,
        },
        {
          className: 'flex-shrink-0',
          width: 40,
          key: 'wins',
          title: 'Wins',
          dataCell: ({ dataItem }) => <span>{dataItem.wins}</span>,
          overflowBreakpoint: CSS_BREAKPOINTS.md,
        },
        {
          className: 'flex-shrink-0',
          width: 40,
          key: 'ties',
          title: 'Ties',
          dataCell: ({ dataItem }) => <span>{dataItem.draws}</span>,
          overflowBreakpoint: CSS_BREAKPOINTS.md,
        },
        {
          className: 'flex-shrink-0',
          width: 40,
          key: 'losses',
          title: 'Losses',
          dataCell: ({ dataItem }) => <span>{dataItem.losses}</span>,
          overflowBreakpoint: CSS_BREAKPOINTS.md,
        },
        {
          className: 'flex-shrink-0',
          width: 40,
          key: 'pd',
          title: 'PD',
          dataCell: ({ dataItem }) => <span>{dataItem.pointsDifference}</span>,
          overflowBreakpoint: CSS_BREAKPOINTS.md,
        },
        {
          className: 'flex-shrink-0',
          width: 40,
          key: 'points',
          title: 'Points',
          dataCell: ({ dataItem }) => <span>{dataItem.points}</span>,
        },
        {
          className: 'flex-shrink-0',
          width: 50,
          key: 'totalScorePoints',
          title: 'TSP',
          dataCell: ({ dataItem }) => <span>{dataItem.totalScorePoints || 0}</span>,
          overflowBreakpoint: CSS_BREAKPOINTS.md,
        },
        {
          className: 'flex-shrink-0 flex-grow-0',
          key: 'actions',
          width: 20,
          dataCell: ({ dataItem }) => {
            if (model.hasHiddenColumns) {
              return (
                <DataTableItemDialog
                  columns={model.columns}
                  dialogTitle={dataItem.teamName}
                  dataItem={dataItem}
                  displayOnlyHiddenColumns
                />
              );
            }
            return null;
          },
          dialogDataCell: () => null,
        },
      ],
      onSort: () => null,
    }),
  );

  return (
    <div>
      <div
        style={!isPublic ? { background: '#e5e6f4', borderRadius: '15px' } : undefined}
        className={`${!isPublic ? 'p-3' : ''}`}
      >
        <DataTable headerClassName='ts-fw-500 pt-0' data={finalLeaderboard} model={model} truncateTable />
      </div>
    </div>
  );
};

interface PoolsLeaderboardProps {
  competitionId: number;
  searchTerm?: string;
}

export const PoolsLeaderboard: React.FC<PoolsLeaderboardProps> = ({ competitionId, searchTerm = '' }) => {
  const leaderboardQuery = useLeaderboardQuery(competitionId);
  const finalLeaderboardQuery = useFinalLeaderboardQuery(competitionId);

  return (
    <div>
      <QueryLoader query={finalLeaderboardQuery}>
        {({ data: finalLeaderboard }) => (
          <div className='d-flex flex-column gap-2 my-3'>
            <h5 className='mb-1'>Final standings</h5>
            <FinalLeaderboard finalLeaderboard={finalLeaderboard} />
          </div>
        )}
      </QueryLoader>
      <QueryLoader query={leaderboardQuery}>
        {({ data: leaderboard }) => (
          <div className='d-flex flex-column gap-2'>
            <h5 className='mb-1'>Group standings</h5>
            {leaderboard.map(pool => (
              <Accordion key={pool.poolId} title={pool.poolName} isDefaultOpen={true}>
                <PoolLeaderboard teams={pool.teams} searchTerm={searchTerm} />
              </Accordion>
            ))}
          </div>
        )}
      </QueryLoader>
    </div>
  );
};
