import { format } from 'date-fns';
import { IRoundProps, ISeedProps } from 'react-brackets';
import { ScheduleDate } from 'src/pages/competitions/schedule/ui/ScheduleFomModel';

export const COMPETITION_STATUS = {
  entered: 'ENTERED',
  notEntered: 'NOT ENTERED',
} as const;
export type COMPETITION_STATUS = ValueOf<typeof COMPETITION_STATUS>;

export const COMPETITION_CATEGORY_TYPE = {
  men: 'men',
  women: 'women',
} as const;
export type COMPETITION_CATEGORY_TYPE = ValueOf<typeof COMPETITION_CATEGORY_TYPE>;

export interface Competition {
  id: number;
  name: string;
  sportName: string;
  sportId: number;
  sportIcon: string | null;
  eventId: number;
  venueId: number;
  venueName: string;
  categoryType: COMPETITION_CATEGORY_TYPE;
  categoryAgeGroup: string;
  minNumberOfPlayersPerTeam: number;
  maxNumberOfPlayersPerTeam: number;
  entriesLimit: number;
  eventStartDate: Date | null;
  eventEndDate: Date | null;
  dates: ScheduleDate[];
  breakTime: number;
  numberOfCourts: number;
  spreadGamesEvenlyBetweenDates: boolean;
  minDobLimit: Date | null;
  maxDobLimit: Date | null;
  status: COMPETITION_STATUS | null;
  entriesCount: number | null;
  matchupFrequency: number;
  matchDuration: number;
  pointsForWin: number;
  pointsForLoss: number;
  isDrawAllowed: boolean;
  pointsForDraw: number;
  isWalkoverAllowed: boolean;
  pointsForAwardedWalkover: number;
  pointsForConcedingWalkover: number;
  showTimeInSchedule: boolean;
  pointsForWalkoverWin: number;
  numberOfQuarters: number;
  hasStarted: boolean;
  productId: number | null;
  productName: string | null;
  eventName: string;
  tieBreaker1: string | null;
  tieBreaker2: string | null;
  tieBreaker3: string | null;
  tieBreaker4: string | null;
  isPublished: boolean;
  termsAndConditionsUrl: string | null;
  secondCategoryAgeGroup: string | null;
  entryAllowed: string | null;
  paidStatus: string | null;
  teamEntryForm: string | null;
  singleEntryForm: string | null;
  depositPaymentProduct: number | null;
  depositPaymentProductId: number | null;
  depositPaymentProductName: string | null;
  teamPaymentProductId: number | null;
  teamPaymentProductName: string | null;
  teamProductFullPrice: number;
  orgId: number;
}

type CompetitionProps = OnlyData<Competition>;

export class Competition {
  constructor(init: CompetitionProps) {
    Object.assign(this, init);
  }
}

export interface CompetitionPool {
  id: number;
  name: string;
  teams: { id: number; name: string }[];
}

type CompetitionPoolProps = OnlyData<CompetitionPool>;

export class CompetitionPool {
  constructor(init: CompetitionPoolProps) {
    Object.assign(this, init);
  }

  getTeamIds(): Set<number> {
    return new Set(this.teams.map(({ id }) => id));
  }
}

export interface CompetitionGame {
  id: number;
  competitionId: number;
  date: Date;
  teamAId: number;
  teamAName: string;
  teamAScore: number;
  teamBId: number;
  teamBName: string;
  teamBScore: number;
  teamATimeout: number;
  teamBTimeout: number;
  court: number;
  timeoutPerTeam: number;
  matchDuration: number;
  timeoutDuration: number;
  scoreBreakdown: {
    teamAScore: number;
    teamBScore: number;
    period: number;
  }[];
  eventId: number;
  videoId: number | null;
  videoThumbnailUrl: string | null;
  stage: string | null;
}

type CompetitionGameProps = OnlyData<CompetitionGame>;

export class CompetitionGame {
  static readonly Status = {
    win: 'win',
    lose: 'lose',
    draw: 'draw',
  } as const;

  constructor(init: CompetitionGameProps) {
    Object.assign(this, init);
  }
}

export interface CompetitionTeamParticipants {
  id: number;
  firstName: string;
  lastName: string;
  jerseyNumber?: number | null;
  postcode?: string;
  dob?: Date;
  email?: string;
  result: CompetitionPlayerResult | null;
}

export interface CompetitionGameWithResults {
  gameId: number;
  eventId: number;
  competitionId: number;
  gameDate: string;
  teamATimeout: number;
  teamBTimeout: number;
  teamARebound: number;
  teamBRebound: number;
  timeoutPerTeam: number;
  matchDuration: number;
  timeoutDuration: number;
  team1: CompetitionTeamWithParticipants;
  team2: CompetitionTeamWithParticipants;
  venueId: number;
  courtNumber: number;
  venue: {
    venueId: number;
    venueAddress1: string;
    venueCity: string;
    venuePostcode: string;
    venueCountry: null;
  };
}

type CompetitionGameWithResultsProps = OnlyData<CompetitionGameWithResults>;

export class CompetitionGameWithResults {
  constructor(init: CompetitionGameWithResultsProps) {
    Object.assign(this, init);
  }
}

export interface CompetitionTeamWithParticipants {
  id: number;
  competitionTeamId: number;
  competitionTeamName: string;
  color: string;
  playersCount: number;
  participants: CompetitionTeamParticipants[];
}

type CompetitionTeamWithParticipantsProps = OnlyData<CompetitionTeamWithParticipants>;

export class CompetitionTeamWithParticipants {
  constructor(init: CompetitionTeamWithParticipantsProps) {
    Object.assign(this, init);
  }
}

export interface CompetitionPlayerResult {
  userId: number;
  firstName: string;
  lastName: string;
  id: number;
  totalPoints: number | null;
  personalFouls: number | null;
  techFouls: number | null;
  unsportmanFouls: number | null;
  disqualifyingFouls: number | null;
  assists: number | null;
  steals: number | null;
  blocks: number | null;
  dunks: number | null;
  turnovers: number | null;
  offensiveRebounds: number | null;
  defensiveRebounds: number | null;
  totalRebounds: number | null;
  ftm: number | null;
  fta: number | null;
  ptm2: number | null;
  pta2: number | null;
  ptm3: number | null;
  pta3: number | null;
  ptm1: number | null;
  pta1: number | null;
  buzzerBeater: number | null;
  buzzerBeater2PT: number | null;
}

type CompetitionPlayerResultsProps = OnlyData<CompetitionPlayerResult>;

export class CompetitionPlayerResult {
  constructor(init: Partial<CompetitionPlayerResultsProps>) {
    this.firstName = init.firstName || '';
    this.lastName = init.lastName || '';
    this.userId = init.userId || 0;

    Object.assign(
      this,
      {
        id: 0,
        totalPoints: 0,
        personalFouls: 0,
        assists: 0,
        steals: 0,
        blocks: 0,
        dunks: 0,
        turnovers: 0,
        offensiveRebounds: 0,
        defensiveRebounds: 0,
        totalRebounds: 0,
        ftm: 0,
        fta: 0,
        ptm2: 0,
        pta2: 0,
        ptm1: 0,
        pta1: 0,
        ptm3: 0,
        pta3: 0,
        buzzerBeater: 0,
        buzzerBeater2PT: 0,
      },
      init,
    );
    Object.assign(this, init);
  }
}

export type CompetitionGameStatus = ValueOf<keyof typeof CompetitionGame.Status>;
export interface CompetitionPoolSchedule {
  id: number;
  name: string;
  games: CompetitionGame[];
}

export interface CompetitionLeaderboardTeam {
  id: number;
  poolName: string;
  poolId: number;
  teamName: string;
  teamId: number;
  points: number;
  wins: number;
  losses: number;
  draws: number;
  pointsDifference: number;
  gamesPlayed: number;
  totalScorePoints: number | null;
}

type CompetitionLeaderboardRecordProps = OnlyData<CompetitionLeaderboardTeam>;

export class CompetitionLeaderboardTeam {
  constructor(init: CompetitionLeaderboardRecordProps) {
    Object.assign(this, init);
  }
}

export type CompetitionLeaderboard = { poolId: number; poolName: string; teams: CompetitionLeaderboardTeam[] }[];

export interface CompetitionFinalLeaderboard {
  id: number;
  teamName: string;
  teamId: number;
  competitionName: string;
  points: number;
  wins: number;
  losses: number;
  draws: number;
  gamesPlayed: number;
  pointsDifference: number;
  totalScorePoints: number | null;
}

interface CompetitionBracketRound {
  title: string;
  games: CompetitionGame[];
}

export interface CompetitionBracket {
  rounds: CompetitionBracketRound[];
  hasEnded: boolean;
  canMoveToNextPhase: boolean;
}

type CompetitionBracketProps = OnlyData<CompetitionBracket>;

export class CompetitionBracket {
  constructor(init: CompetitionBracketProps) {
    Object.assign(this, init);
  }

  getRenderData(): IRoundProps[] {
    this.transformBracket();
    return this.rounds.map<IRoundProps>(round => ({
      title: round.title,
      seeds: round.games.map<ISeedProps>(game => {
        const statusTeamA =
          Number(game.teamAScore) === Number(game.teamBScore)
            ? CompetitionGame.Status.draw
            : Number(game.teamAScore) > Number(game.teamBScore)
            ? CompetitionGame.Status.win
            : CompetitionGame.Status.lose;
        const statusTeamB =
          Number(game.teamAScore) === Number(game.teamBScore)
            ? CompetitionGame.Status.draw
            : Number(game.teamBScore) > Number(game.teamAScore)
            ? CompetitionGame.Status.win
            : CompetitionGame.Status.lose;
        return {
          teams: [
            { name: game.teamAName, score: game.teamAScore, status: statusTeamA },
            { name: game.teamBName, score: game.teamBScore, status: statusTeamB },
          ],
          id: game.id,
          date: `${format(game.date, 'dd/MM/yyyy - HH:mm')} - Court: ${game.court}`,
          game,
        };
      }),
    }));
  }

  // eslint-disable-next-line class-methods-use-this
  private isPowerOfTwo(n: number): boolean {
    return Boolean(n && (n & (n - 1)) === 0);
  }

  private getTeamsInEarliestRound(): number {
    if (!this.rounds.length) return 0;

    const earliestRound = this.rounds[0];
    const nextRound = this.rounds[1];

    // Count teams in earliest round
    let teamCount = earliestRound.games.length * 2;

    // Add teams that got byes (already in next round)
    if (nextRound) {
      const teamsInNextRound = new Set<string>();
      nextRound.games.forEach(game => {
        if (game.teamAName && game.teamAName !== 'TBD') teamsInNextRound.add(game.teamAName);
        if (game.teamBName && game.teamBName !== 'TBD') teamsInNextRound.add(game.teamBName);
      });
      teamCount += teamsInNextRound.size;
    }

    return teamCount;
  }

  private createDummyGame(teamWithBye: string, date: Date, court: number): CompetitionGame {
    return new CompetitionGame({
      id: Math.random(),
      competitionId: this.rounds[0].games[0].competitionId,
      date,
      teamAName: teamWithBye,
      teamBName: 'Seeded',
      teamAScore: 0,
      teamBScore: 0,
      teamAId: -1,
      teamBId: -1,
      teamATimeout: 0,
      teamBTimeout: 0,
      court,
      timeoutPerTeam: 0,
      matchDuration: 0,
      timeoutDuration: 0,
      scoreBreakdown: [{ period: 1, teamAScore: 0, teamBScore: 0 }],
      eventId: this.rounds[0].games[0].eventId,
      videoId: null,
      videoThumbnailUrl: null,
      stage: this.rounds[0].title,
    });
  }

  private transformBracket(): void {
    const totalTeams = this.getTeamsInEarliestRound();
    if (this.isPowerOfTwo(totalTeams) || totalTeams === 0) return;

    const rounds = [...this.rounds];
    const hasThirdPlace = rounds[rounds.length - 1].title === 'Third Place';
    const mainRounds = hasThirdPlace ? rounds.slice(0, -1) : rounds;

    for (let i = 0; i < mainRounds.length - 1; i++) {
      const currentRound = mainRounds[i];
      const nextRound = mainRounds[i + 1];
      const expectedGames = Math.pow(2, mainRounds.length - i - 1);

      if (currentRound.games.length < expectedGames) {
        const teamsWithByePositions = new Map<string, number>();
        nextRound.games.forEach((game, index) => {
          if (game.teamAName && game.teamAName !== 'TBD') teamsWithByePositions.set(game.teamAName, index);
          if (game.teamBName && game.teamBName !== 'TBD') teamsWithByePositions.set(game.teamBName, index);
        });

        const newGames: CompetitionGame[] = new Array(expectedGames);
        const byeTeams = Array.from(teamsWithByePositions.entries());

        if (totalTeams === 10) {
          // Handle 10 teams case (6 byes)
          const realMatches = currentRound.games;
          let byeIndex = 0;
          let realMatchIndex = 0;

          // Place bye matches and real matches in the correct order
          for (let j = 0; j < expectedGames; j++) {
            if (j === 0 || j === 2 || j === 3 || j === 4 || j === 5 || j === 7) {
              // Place bye matches at specific indices to align with quarter-finals
              if (byeIndex < byeTeams.length) {
                newGames[j] = this.createDummyGame(
                  byeTeams[byeIndex][0],
                  currentRound.games[0].date,
                  currentRound.games[0].court,
                );
                byeIndex++;
              }
            } else if (realMatchIndex < realMatches.length) {
              // Place real matches in between bye matches
              newGames[j] = realMatches[realMatchIndex];
              realMatchIndex++;
            } else {
              // Fill remaining slots with dummy games
              newGames[j] = this.createDummyGame('TBD', currentRound.games[0].date, currentRound.games[0].court);
            }
          }
        } else if (totalTeams === 14) {
          // Handle 14 teams case (2 byes)
          const realMatches = currentRound.games;

          // Place first bye at the start
          newGames[0] = this.createDummyGame(byeTeams[0][0], currentRound.games[0].date, currentRound.games[0].court);

          // Place real matches in the middle
          for (let j = 0; j < realMatches.length; j++) {
            newGames[j + 1] = realMatches[j];
          }

          // Place second bye at the end
          newGames[expectedGames - 1] = this.createDummyGame(
            byeTeams[1][0],
            currentRound.games[0].date,
            currentRound.games[0].court,
          );
        } else {
          // Handle other cases (6, 12 teams) with original logic
          currentRound.games.forEach((game, index) => {
            const targetPosition = index * 2 + 1;
            newGames[targetPosition] = game;
          });

          teamsWithByePositions.forEach((nextRoundIndex, teamName) => {
            const targetPosition = nextRoundIndex * 2;
            newGames[targetPosition] = this.createDummyGame(
              teamName,
              currentRound.games[0].date,
              currentRound.games[0].court,
            );
          });
        }

        // Fill any remaining undefined slots
        for (let j = 0; j < expectedGames; j++) {
          if (!newGames[j]) {
            newGames[j] = this.createDummyGame('TBD', currentRound.games[0].date, currentRound.games[0].court);
          }
        }

        rounds[i].games = newGames;
      }
    }

    this.rounds = hasThirdPlace ? [...mainRounds, rounds[rounds.length - 1]] : mainRounds;
  }
}

export interface CompetitionTeam {
  id: number;
  name: string;
  playersCount: number;
  color: string;
}

type CompetitionTeamProps = OnlyData<CompetitionTeam>;

export class CompetitionTeam {
  constructor(init: CompetitionTeamProps) {
    Object.assign(this, init);
  }
}

export interface CompetitionGameLogs {
  id: number;
  team: {
    id: number;
    name: string;
    color: string;
  };
  player: {
    id: number;
    firstName: string;
    lastName: string;
    jerseyNumber: number | null;
  } | null;
  action: string;
  time: string;
  period: number | null;
  timeoutMinutesRemaining?: number | null;
}

type CompetitionGameLogsProps = OnlyData<CompetitionGameLogs>;

export class CompetitionGameLogs {
  constructor(init: CompetitionGameLogsProps) {
    Object.assign(this, init);
  }
}
