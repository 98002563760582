import { format } from 'date-fns';
import { IRoundProps, ISeedProps } from 'react-brackets';
import { LYGCompetitionGame } from 'src/lyg/entities/competitions/LYGCompetitionGame';

interface LYGCompetitionBracketRound {
  title: string;
  games: LYGCompetitionGame[];
}

export interface LYGCompetitionBracket {
  rounds: LYGCompetitionBracketRound[];
  hasEnded: boolean;
  canMoveToNextPhase: boolean;
}

type LYGCompetitionBracketProps = OnlyData<LYGCompetitionBracket>;

export class LYGCompetitionBracket {
  constructor(init: LYGCompetitionBracketProps) {
    Object.assign(this, init);
  }

  getRenderData(): IRoundProps[] {
    this.transformBracket();
    return this.rounds.map<IRoundProps>(round => ({
      title: round.title,
      seeds: round.games.map<ISeedProps>(game => {
        const statusTeamA =
          Number(game.teamAScore) === Number(game.teamBScore)
            ? LYGCompetitionGame.Status.draw
            : Number(game.teamAScore) > Number(game.teamBScore)
            ? LYGCompetitionGame.Status.win
            : LYGCompetitionGame.Status.lose;
        const statusTeamB =
          Number(game.teamAScore) === Number(game.teamBScore)
            ? LYGCompetitionGame.Status.draw
            : Number(game.teamBScore) > Number(game.teamAScore)
            ? LYGCompetitionGame.Status.win
            : LYGCompetitionGame.Status.lose;
        return {
          teams: [
            { name: game.teamAName, score: game.teamAScore, status: statusTeamA },
            { name: game.teamBName, score: game.teamBScore, status: statusTeamB },
          ],
          id: game.id,
          date: `${format(game.date, 'dd/MM/yyyy - HH:mm')} - Court: ${game.court}`,
          game,
        };
      }),
    }));
  }

  // eslint-disable-next-line class-methods-use-this
  private isPowerOfTwo(n: number): boolean {
    return Boolean(n && (n & (n - 1)) === 0);
  }

  private getTeamsInEarliestRound(): number {
    if (!this.rounds.length) return 0;

    const earliestRound = this.rounds[0];
    const nextRound = this.rounds[1];

    // Count teams in earliest round
    let teamCount = earliestRound.games.length * 2;

    // Add teams that got byes (already in next round)
    if (nextRound) {
      const teamsInNextRound = new Set<string>();
      nextRound.games.forEach(game => {
        if (game.teamAName && game.teamAName !== 'TBD') teamsInNextRound.add(game.teamAName);
        if (game.teamBName && game.teamBName !== 'TBD') teamsInNextRound.add(game.teamBName);
      });
      teamCount += teamsInNextRound.size;
    }

    return teamCount;
  }

  private createDummyGame(teamWithBye: string, date: Date, court: number): LYGCompetitionGame {
    return new LYGCompetitionGame({
      id: Math.random(),
      competitionId: this.rounds[0].games[0].competitionId,
      date,
      teamAName: teamWithBye,
      teamBName: 'Seeded',
      teamAScore: 0,
      teamBScore: 0,
      teamAId: -1,
      teamBId: -1,
      court,
      scoreBreakdown: [{ period: 1, teamAScore: 0, teamBScore: 0 }],
    });
  }

  private transformBracket(): void {
    const totalTeams = this.getTeamsInEarliestRound();
    if (this.isPowerOfTwo(totalTeams) || totalTeams === 0) return;

    const rounds = [...this.rounds];
    const hasThirdPlace = rounds[rounds.length - 1].title === 'Third Place';
    const mainRounds = hasThirdPlace ? rounds.slice(0, -1) : rounds;

    for (let i = 0; i < mainRounds.length - 1; i++) {
      const currentRound = mainRounds[i];
      const nextRound = mainRounds[i + 1];
      const expectedGames = Math.pow(2, mainRounds.length - i - 1);

      if (currentRound.games.length < expectedGames) {
        const teamsWithByePositions = new Map<string, number>();
        nextRound.games.forEach((game, index) => {
          if (game.teamAName && game.teamAName !== 'TBD') teamsWithByePositions.set(game.teamAName, index);
          if (game.teamBName && game.teamBName !== 'TBD') teamsWithByePositions.set(game.teamBName, index);
        });

        const newGames: LYGCompetitionGame[] = new Array(expectedGames);
        const byeTeams = Array.from(teamsWithByePositions.entries());

        if (totalTeams === 10) {
          // Handle 10 teams case (6 byes)
          const realMatches = currentRound.games;
          let byeIndex = 0;
          let realMatchIndex = 0;

          // Place bye matches and real matches in the correct order
          for (let j = 0; j < expectedGames; j++) {
            if (j === 0 || j === 2 || j === 3 || j === 4 || j === 5 || j === 7) {
              // Place bye matches at specific indices to align with quarter-finals
              if (byeIndex < byeTeams.length) {
                newGames[j] = this.createDummyGame(
                  byeTeams[byeIndex][0],
                  currentRound.games[0].date,
                  currentRound.games[0].court,
                );
                byeIndex++;
              }
            } else if (realMatchIndex < realMatches.length) {
              // Place real matches in between bye matches
              newGames[j] = realMatches[realMatchIndex];
              realMatchIndex++;
            } else {
              // Fill remaining slots with dummy games
              newGames[j] = this.createDummyGame('TBD', currentRound.games[0].date, currentRound.games[0].court);
            }
          }
        } else if (totalTeams === 14) {
          // Handle 14 teams case (2 byes)
          const realMatches = currentRound.games;

          // Place first bye at the start
          newGames[0] = this.createDummyGame(byeTeams[0][0], currentRound.games[0].date, currentRound.games[0].court);

          // Place real matches in the middle
          for (let j = 0; j < realMatches.length; j++) {
            newGames[j + 1] = realMatches[j];
          }

          // Place second bye at the end
          newGames[expectedGames - 1] = this.createDummyGame(
            byeTeams[1][0],
            currentRound.games[0].date,
            currentRound.games[0].court,
          );
        } else {
          // Handle other cases (6, 12 teams) with original logic
          currentRound.games.forEach((game, index) => {
            const targetPosition = index * 2 + 1;
            newGames[targetPosition] = game;
          });

          teamsWithByePositions.forEach((nextRoundIndex, teamName) => {
            const targetPosition = nextRoundIndex * 2;
            newGames[targetPosition] = this.createDummyGame(
              teamName,
              currentRound.games[0].date,
              currentRound.games[0].court,
            );
          });
        }

        // Fill any remaining undefined slots
        for (let j = 0; j < expectedGames; j++) {
          if (!newGames[j]) {
            newGames[j] = this.createDummyGame('TBD', currentRound.games[0].date, currentRound.games[0].court);
          }
        }

        rounds[i].games = newGames;
      }
    }

    this.rounds = hasThirdPlace ? [...mainRounds, rounds[rounds.length - 1]] : mainRounds;
  }
}
