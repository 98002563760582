import { LYGCompetitionLeaderboardTeam } from 'src/lyg/entities/competitions/LYGCompetitionLeaderboard';
import React, { useState } from 'react';
import { DataTableModel } from 'src/shared/ui/data/DataTableModel';
import { DataTable } from 'src/shared/ui/data/DataTable';

interface PoolLeaderboardProps {
  teams: LYGCompetitionLeaderboardTeam[];
  bloatTextPattern: RegExp;
}

export const PoolLeaderboard: React.FC<PoolLeaderboardProps> = ({ teams, bloatTextPattern }) => {
  const removeBloatText = (str: string) => {
    return str.replace(bloatTextPattern, '');
  };

  const [model] = useState(
    new DataTableModel<LYGCompetitionLeaderboardTeam>({
      columns: [
        {
          className: 'flex-shrink-0 flex-grow-1',
          width: 200,
          key: 'team',
          title: 'Team',
          dataCell: ({ dataItem }) => <span>{removeBloatText(dataItem.teamName)}</span>,
        },
        {
          className: 'flex-shrink-0',
          width: 50,
          key: 'played',
          title: 'Played',
          dataCell: ({ dataItem }) => <span>{dataItem.gamesPlayed}</span>,
        },
        {
          className: 'flex-shrink-0',
          width: 50,
          key: 'wins',
          title: 'Wins',
          dataCell: ({ dataItem }) => <span>{dataItem.wins}</span>,
        },
        {
          className: 'flex-shrink-0',
          width: 50,
          key: 'ties',
          title: 'Ties',
          dataCell: ({ dataItem }) => <span>{dataItem.draws}</span>,
        },
        {
          className: 'flex-shrink-0',
          width: 50,
          key: 'losses',
          title: 'Losses',
          dataCell: ({ dataItem }) => <span>{dataItem.losses}</span>,
        },
        {
          className: 'flex-shrink-0',
          width: 50,
          key: 'pd',
          title: 'PD',
          dataCell: ({ dataItem }) => <span>{dataItem.pointsDifference}</span>,
        },
        {
          className: 'flex-shrink-0',
          width: 50,
          key: 'points',
          title: 'Points',
          dataCell: ({ dataItem }) => <span>{dataItem.points}</span>,
        },
        {
          className: 'flex-shrink-0',
          width: 50,
          key: 'totalScorePoints',
          title: 'TSP',
          dataCell: ({ dataItem }) => <span>{dataItem.totalScorePoints || 0}</span>,
        },
      ],
      onSort: () => null,
    }),
  );

  return (
    <div style={{ width: '100%', overflowX: 'scroll' }}>
      <div style={{ width: 'fit-content', minWidth: '100%' }}>
        <DataTable headerClassName='ts-fw-500' data={teams} model={model} />
      </div>
    </div>
  );
};
