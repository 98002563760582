import React from 'react';
import { cx } from 'src/shared/utils/common';
import { observer } from 'mobx-react-lite';
import { Check } from 'react-feather';
import { BooleanFieldModel } from 'src/shared/ui/inputs/boolean/BooleanFieldModel';
import css from './CheckboxField.module.scss';

interface CheckboxFieldProps {
  model: BooleanFieldModel;
}

export const CheckboxField: React.FC<CheckboxFieldProps> = observer(({ model }) => {
  const { disabled, id, label, title, value, setValue, helperText } = model;

  return (
    <div className='d-flex flex-column gap-2'>
      <div
        className={cx(
          'd-flex align-items-center user-select-none position-relative',
          css.field,
          disabled && css.disabledField,
        )}
      >
        <input
          aria-label={title}
          checked={value}
          className='position-absolute'
          disabled={disabled}
          id={id}
          name={id}
          title={title}
          type='checkbox'
          onChange={disabled ? undefined : () => setValue(!value)}
        />
        <div className={cx('flex-shrink-0', css.checkbox, value && css.checked, disabled && css.disabled)}>
          {value ? <Check /> : null}
        </div>
        {label && (
          <label className='ts-text-light' htmlFor={id}>
            {label}
          </label>
        )}
      </div>
      {helperText && <p className='mb-0 ts-text-lighter ts-fs-10'>{helperText}</p>}
    </div>
  );
});
