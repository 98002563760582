import React from 'react';
import { RotateCw } from 'react-feather';
import { useI18nContext } from 'src/context/i18n';
import { useLYGStartBracketPhaseMutation } from 'src/lyg/pages/competitions/bracket/mutation';
import { useLYGCompetitionBracketQuery } from 'src/lyg/pages/competitions/bracket/query';
import { useLYGCompetitionPoolsQuery, useLYGCompetitionQuery } from 'src/lyg/pages/competitions/pools/query';
import { useLYGLeaderboardQuery } from 'src/lyg/pages/competitions/schedule/query';
import { CancelButton, PrimaryButton } from 'src/shared/ui/buttons';
import { BooleanFieldModel } from 'src/shared/ui/inputs/boolean/BooleanFieldModel';
import { CheckboxField } from 'src/shared/ui/inputs/boolean/CheckboxField';
import { ModalDialog } from 'src/shared/ui/modal/ModalDialog';
import { ErrorMessage } from 'src/shared/ui/utils/ErrorMessage';
import { observer } from 'mobx-react-lite';
import { useCurrentUserRole } from 'src/context/authContext';
import { DropdownFieldModel } from 'src/shared/ui/inputs/dropdown/DropdownFieldModel';
import { DropdownField } from 'src/shared/ui/inputs/dropdown/DropdownField';
import { FormFieldWrapper } from 'src/shared/ui/inputs/FormFieldWrapper';
import { DropdownButton } from 'src/shared/ui/buttons/DropdownButton';

interface LYGStartBracketPhaseButtonProps {
  competitionId: number;
  isStartBracketOnly?: boolean;
  onSuccess?(): void;
}

// function getGamesPerTeamCount(pools: LYGCompetitionPool[] | undefined, matchupFrequency: number | undefined): number {
//   if (!pools || !matchupFrequency) return 0;
//   const pool = pools[0];
//   const teamsCount = pool.teams.length;
//   return (teamsCount - 1) * matchupFrequency;
// }

export const LYGStartBracketPhaseButton: React.FC<LYGStartBracketPhaseButtonProps> = observer(
  ({ competitionId, onSuccess, isStartBracketOnly = false }) => {
    const i18n = useI18nContext();
    const [isOpen, setIsOpen] = React.useState(false);

    const bracketQuery = useLYGCompetitionBracketQuery(competitionId);
    const poolsQuery = useLYGCompetitionPoolsQuery(competitionId);

    const noOfTeams = poolsQuery.data?.reduce((acc, val) => acc + val.teams.length, 0) as number;

    // TODO: move to utils
    const generateTeamCountOptions = (totalTeams: number) => {
      const maxTeams = Math.min(16, totalTeams);
      const effectiveMax = maxTeams % 2 === 0 ? maxTeams : maxTeams - 1;

      const options = [];
      for (let i = 2; i <= effectiveMax; i += 2) {
        options.push({
          id: i.toString(),
          label: i.toString(),
        });
      }
      return options;
    };

    const [teamsCountDropdownModel] = React.useState(
      new DropdownFieldModel({
        i18n,
        id: 'bracketType',
        initialValue: {
          id: '2',
          label: '2',
        },
        required: true,
      }),
    );

    const [toggleModel] = React.useState(
      new BooleanFieldModel({
        i18n,
        title: 'Allow 3rd place',
        id: 'runnerUpsCheckbox',
        initialValue: false,
        label: 'Allow 3rd place',
        // disabled: noOfTeams % 2 === 0 ? false : true,
      }),
    );

    const { mutate, isLoading, error } = useLYGStartBracketPhaseMutation(competitionId);

    React.useEffect(() => {
      if (noOfTeams) {
        teamsCountDropdownModel.setOptions(generateTeamCountOptions(noOfTeams));
      }
    }, [noOfTeams, teamsCountDropdownModel]);

    return (
      <>
        {isOpen && (
          <ModalDialog
            closeDialog={() => setIsOpen(false)}
            title={bracketQuery.data?.rounds?.length ? 'Re-start Bracket Phase' : 'Start Bracket Phase'}
          >
            {bracketQuery.data?.rounds?.length ? (
              <p className='mb-3 ts-text-error ts-fw-500'>
                Are you sure you want to re-start the bracket phase? This will delete all the points and data related to
                the current one
              </p>
            ) : null}

            <p className='mb-3'>Number of teams in the knockout stage</p>

            <div className='row align-items-center mb-3'>
              <div className='col-6'>
                <FormFieldWrapper model={teamsCountDropdownModel} className='mb-0'>
                  <DropdownField model={teamsCountDropdownModel} isSmall />
                </FormFieldWrapper>
              </div>
              <div className='col-6'>
                <CheckboxField model={toggleModel} />
              </div>
            </div>

            <div>
              <p className='mb-2'>
                <strong>Info:</strong> If you select fewer than the total number of teams, the remaining are being
                eliminated out of the group stage
              </p>
              <p className='mb-2'>
                <strong>Info:</strong> When we have even number of pools - the top teams across pools will be selected
              </p>
              <p className='mb-3'>
                <strong>Info:</strong> When we have odd number of pools - the top teams overall will be selected
              </p>
            </div>

            <div className='d-flex gap-2 mt-4'>
              <CancelButton className='w-100' onClick={() => setIsOpen(false)} disabled={isLoading}>
                Cancel
              </CancelButton>
              <PrimaryButton
                className='w-100'
                onClick={() =>
                  mutate(
                    {
                      numberOfTeams: teamsCountDropdownModel.value.id,
                      includeRunnerUps: toggleModel.value,
                    },
                    {
                      onSuccess: () => {
                        onSuccess?.();
                        setIsOpen(false);
                      },
                    },
                  )
                }
                loading={isLoading}
                disabled={teamsCountDropdownModel.hasError}
              >
                {bracketQuery.data?.rounds?.length ? 'Re-start Bracket Phase' : 'Start Bracket Phase'}
              </PrimaryButton>
              {error && <ErrorMessage message={error.message} />}
            </div>
          </ModalDialog>
        )}

        {bracketQuery.data?.rounds?.length ? (
          !isStartBracketOnly ? (
            <DropdownButton
              options={[
                {
                  label: 'Re-start Bracket Phase',
                  onClick: () => setIsOpen(true),
                },
              ]}
            />
          ) : null
        ) : isStartBracketOnly ? (
          <PrimaryButton loading={isLoading} onClick={() => setIsOpen(true)}>
            Start Bracket Phase
          </PrimaryButton>
        ) : null}
      </>
    );
  },
);
