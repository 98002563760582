import { StartEndDates } from 'src/shared/utils/dates';

export const TEAM_QUERY_KEYS = {
  base: ['teams'],
  count: (): string[] => [...TEAM_QUERY_KEYS.base, 'count'],
  tags: (teamId: number, areStatsTags = false): unknown[] => [...TEAM_QUERY_KEYS.team(teamId), 'tags', areStatsTags],
  tagsCount: (): unknown[] => [...TEAM_QUERY_KEYS.base, 'tags', 'count'],
  team: (teamId: number): unknown[] => [...TEAM_QUERY_KEYS.base, teamId],
  teamPlayer: (teamId: number): unknown[] => [...TEAM_QUERY_KEYS.team(teamId), 'teamPlayer'],
  targets: (teamId: number): unknown[] => [...TEAM_QUERY_KEYS.team(teamId), 'targets'],
  filtered: (filters: AnyObject): unknown[] => [...TEAM_QUERY_KEYS.base, filters],
  leaderboard: (year: number): unknown[] => [...TEAM_QUERY_KEYS.base, 'leaderboard', year],
  activePlayers: (year: number): unknown[] => [...TEAM_QUERY_KEYS.base, 'activePlayers', year],
  ratingsAverage: (teamId: number, dates: StartEndDates) => [...TEAM_QUERY_KEYS.team(teamId), 'ratingsAverage', dates],
};
