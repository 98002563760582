import { useMutation, useQueryClient } from 'react-query';
import { useAuthContext } from 'src/context/authContext';
import { LYG_COMPETITIONS_KEYS } from 'src/lyg/pages/competitions/queryKeys';
import { apiClient } from 'src/lyg/utils/apiClient';
import { ApiError } from 'src/lyg/utils/apiError';
import { dateToISONoTimezone } from 'src/shared/utils/dates';

export interface BracketConfig {
  numberOfTeams: string;
  includeRunnerUps: boolean;
}

function startBracketPhase(competitionId: number, bracketConfig: BracketConfig, token: AccessToken): Promise<void> {
  return apiClient(`/v1/lyg/competitions/${competitionId}/bracket`, {
    token,
    method: 'POST',
    body: JSON.stringify({
      number_of_teams: Number(bracketConfig.numberOfTeams),
      include_runnerups: bracketConfig.includeRunnerUps ? 1 : 0,
    }),
  });
}

export function useLYGStartBracketPhaseMutation(competitionId: number) {
  const { token } = useAuthContext();
  const queryClient = useQueryClient();

  return useMutation<void, ApiError, BracketConfig>(
    bracketConfig => startBracketPhase(competitionId, bracketConfig, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(LYG_COMPETITIONS_KEYS.bracket(competitionId));
      },
    },
  );
}

function computeNextBracketPhase(competitionId: number, token: AccessToken): Promise<void> {
  return apiClient(`/v1/lyg/competitions/${competitionId}/bracket/next`, { token, method: 'POST' });
}

export function useLYGComputeNextBracketPhaseMutation(competitionId: number) {
  const { token } = useAuthContext();
  const queryClient = useQueryClient();

  return useMutation<void, ApiError>(() => computeNextBracketPhase(competitionId, token), {
    onSuccess: () => {
      queryClient.invalidateQueries(LYG_COMPETITIONS_KEYS.bracket(competitionId));
    },
  });
}

interface UpdateGameData {
  date: Date;
  courtNumber: number;
  gameId: number;
  competitionId: number;
}

function updateGameDate(request: UpdateGameData, token: AccessToken): Promise<void> {
  return apiClient(`/v1/lyg/competitions/${request.competitionId}/game/${request.gameId}`, {
    token,
    method: 'POST',
    body: JSON.stringify({
      lyg_game_date: dateToISONoTimezone(request.date, true),
      lyg_court_number: request.courtNumber,
    }),
  });
}

export function useLYGEditGameDateMutation(competitionId: number) {
  const { token } = useAuthContext();
  const queryClient = useQueryClient();

  return useMutation<void, ApiError, UpdateGameData>(data => updateGameDate(data, token), {
    onSuccess: () => {
      queryClient.invalidateQueries(LYG_COMPETITIONS_KEYS.bracket(competitionId));
      queryClient.invalidateQueries(LYG_COMPETITIONS_KEYS.schedule(competitionId));
      queryClient.invalidateQueries(LYG_COMPETITIONS_KEYS.competition(competitionId));
      queryClient.invalidateQueries(LYG_COMPETITIONS_KEYS.pools(competitionId));
    },
  });
}
