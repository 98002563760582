import React, { CSSProperties } from 'react';
import { cx } from 'src/shared/utils/common';
import { observer } from 'mobx-react-lite';
import { useI18nContext } from 'src/context/i18n';
import { Card } from 'src/shared/ui/widgets/Card';
import { DataTableDataItem } from 'src/shared/ui/data/types';
import { DataTableColumnHeader } from 'src/shared/ui/data/DataTableColumnHeader';
import { DataTableModel } from 'src/shared/ui/data/DataTableModel';
import { useOnElementResize } from 'src/shared/hooks/useOnElementResize';
import css from './DataTable.module.scss';
import { PrimaryInvertedButton } from 'src/shared/ui/buttons';

interface DataTableProps<T extends DataTableDataItem> {
  data: T[];
  model: DataTableModel<T>;
  idPropName?: keyof T;
  cardClassName?: string;
  tableRowClassName?: string;
  wrapperClassName?: string;
  headerClassName?: string;
  stripped?: boolean;
  style?: CSSProperties;
  truncateTable?: boolean;
}

export const DataTable = observer(
  <T extends DataTableDataItem>({
    data,
    model,
    cardClassName,
    tableRowClassName,
    wrapperClassName,
    headerClassName,
    stripped,
    idPropName = 'id',
    style,
    truncateTable = false,
  }: DataTableProps<T>): JSX.Element => {
    const i18n = useI18nContext();
    const { columns, showColumnHeaders } = model;
    const [showAll, setShowAll] = React.useState(false);

    const tableWrapperRef = useOnElementResize(({ width }) => {
      model.setWidth(width);
    });

    const displayData = React.useMemo(() => {
      if (!truncateTable || showAll) {
        return data;
      }
      return data.slice(0, 10);
    }, [data, truncateTable, showAll]);

    return (
      <div ref={tableWrapperRef} className={wrapperClassName}>
        {showColumnHeaders && (
          <div
            className={cx(
              'd-flex align-items-center justify-content-between ts-fs-13',
              css.tableHeaderRow,
              headerClassName,
              tableRowClassName,
            )}
          >
            {columns.map(column => (
              <DataTableColumnHeader model={column} key={column.props.key} />
            ))}
          </div>
        )}

        <Card className={cx(css.tableCard, cardClassName)} style={style}>
          {data.length > 0 ? (
            <>
              {displayData.map((dataItem, index) => (
                <div key={dataItem[idPropName]} className={cx(tableRowClassName, css.tableRow)}>
                  <div className={cx('d-flex align-items-center justify-content-between')}>
                    {columns.map((column, cIdx) => {
                      if (
                        Number(column.props.overflowBreakpoint) > model.width ||
                        (column.props.hideFromTable && column.props.hideFromTable(model))
                      ) {
                        return null;
                      }

                      return (
                        <div
                          className={cx(
                            'text-break',
                            css.tableCell,
                            column.props.className,
                            stripped && cIdx % 2 !== 0 && css.stripped,
                          )}
                          key={column.props.key}
                          style={{ width: column.props.width }}
                        >
                          {column.props.dataCell({ dataItem, table: model, index })}
                        </div>
                      );
                    })}
                  </div>

                  {model.extraRowDataCell && model.extraRowDataCell({ dataItem, table: model, index }) && (
                    <div className={cx(css.tableRow, 'pb-0')}>
                      {model.extraRowDataCell({ dataItem, table: model, index })}
                    </div>
                  )}
                </div>
              ))}

              {truncateTable && data.length > 10 && (
                <div className='text-center'>
                  <PrimaryInvertedButton className='mt-3 mb-0' onClick={() => setShowAll(!showAll)}>
                    {showAll ? 'Show less...' : 'Show more...'}
                  </PrimaryInvertedButton>
                </div>
              )}
            </>
          ) : (
            <p className='py-5 text-center'>{model.props.noDataMessage || i18n.t('shared.phrases.noData')}</p>
          )}
        </Card>
      </div>
    );
  },
);
