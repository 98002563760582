import { useEffect, useState } from 'react';

type Orientation = 'portrait' | 'landscape';

export const checkOrientation = (): Orientation => {
  // Check screen.orientation API first
  if (window?.screen?.orientation?.type) {
    return window.screen.orientation.type.includes('landscape') ? 'landscape' : 'portrait';
  }

  // Fallback to window.orientation
  if (typeof window.orientation === 'number') {
    return Math.abs(window.orientation) === 90 ? 'landscape' : 'portrait';
  }

  // Last resort - check window dimensions
  return window.innerWidth > window.innerHeight ? 'landscape' : 'portrait';
};

export const useIsLandscape = () => {
  const [orientation, setOrientation] = useState<Orientation>(checkOrientation());

  const onOrientationChange = () => {
    setOrientation(checkOrientation());
  };

  useEffect(() => {
    // Listen to both modern and legacy orientation events
    window.addEventListener('orientationchange', onOrientationChange);
    window.addEventListener('resize', onOrientationChange);

    if (window?.screen?.orientation) {
      screen.orientation.addEventListener('change', onOrientationChange);
    }

    return () => {
      window.removeEventListener('orientationchange', onOrientationChange);
      window.removeEventListener('resize', onOrientationChange);

      if (window?.screen?.orientation) {
        screen.orientation.removeEventListener('change', onOrientationChange);
      }
    };
  }, []);

  return orientation === 'landscape';
};
